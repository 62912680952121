import Chip from '@material-ui/core/Chip';
import { grey } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { nls } from 'locale/language';
import { Vi } from 'query/issue/types';
import React from 'react';
import styled from 'styled-components';
import { VI_COLOR } from 'styles/colors/symbols';
import { hexToRgba } from 'styles/colors/utils';
import theme from 'styles/mui/theme';

export default function SummaryBoard({ vi }: { vi: Vi }) {
  return (
    <Box>
      <SummaryItem>
        <StatusChip label={nls.issuePolygonNdviHealthyChip()} $color={VI_COLOR.HEALTHY} />
        <Value $color={VI_COLOR.HEALTHY}>{vi?.ndviSummary?.healthy}%</Value>
      </SummaryItem>
      <SummaryDivider />
      <SummaryItem>
        <StatusChip label={nls.issuePolygonNdviStressedChip()} $color={VI_COLOR.STRESSED} />
        <Value $color={VI_COLOR.STRESSED}>{vi?.ndviSummary?.stressed}%</Value>
      </SummaryItem>
      <SummaryDivider />
      <SummaryItem>
        <StatusChip label={nls.issuePolygonNdviCriticalChip()} $color={VI_COLOR.CRITICAL} />
        <Value $color={VI_COLOR.CRITICAL}>{vi?.ndviSummary?.critical}%</Value>
      </SummaryItem>
    </Box>
  );
}

const Box = styled.div`
  background-color: ${theme.palette.text.primary};
  border-radius: 0.25rem;
  display: flex;
  margin-top: 0.5rem;
  padding: 0.625rem 0.75rem 0.5rem;
`;

const SummaryItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  &:nth-child(2) {
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    padding: 0 0.625rem;
  }
`;

const SummaryDivider = styled(Divider).attrs({ flexItem: true, orientation: 'vertical' })`
  background-color: ${grey[800]};
  margin: 0 0.625rem;
`;

const StatusChip = styled(Chip).attrs({ size: 'small' })<{ $color: string }>`
  background-color: ${({ $color }) => hexToRgba($color, 0.2)};
  color: ${({ $color }) => $color};
  &.MuiChip-sizeSmall {
    height: auto;
  }
  .MuiChip-labelSmall {
    padding: 0.1875rem 0.625rem;
    line-height: 1;
  }
`;

const Value = styled(Typography).attrs({ variant: 'h6' })<{ $color: string }>`
  color: ${({ $color }) => $color};
`;
