import { nls } from 'locale/language';
import { CreatingBulkPolygon } from 'query/annotation/types';

export const leafletCreatingBulkPolygonsStyle = `
.leaflet-creating-bulk-polygons {
  z-index: 650;
}
`;

export function getNewTempId(x: CreatingBulkPolygon[]): number {
  return x.reduce((acc, cur) => Math.max(acc, cur.tempId), 0) + 1;
}

export function getNewName(polygonLength: number) {
  return `${nls.dtmBulkPolygonName()} ${polygonLength + 1}`;
}
