import { atom } from 'jotai';
import { CadastralParcel, CompensationStatus } from 'store/v2/snapshot/types';

export type CadastralItemKey = Exclude<CompensationStatus, CompensationStatus.Undefined> | 'map';

const enabled = atom<boolean>(false);
const expanded = atom<boolean>(false);
const visibleItems = atom<{ [key in CadastralItemKey]: boolean }>({
  required: false,
  pending: false,
  done: false,
  map: false,
});
const focusedParcel = atom<CadastralParcel>(null as CadastralParcel);

export const cadastralAtom = {
  enabled,
  expanded,
  visibleItems,
  focusedParcel,
};
