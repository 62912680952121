import { combineReducers, Reducer } from 'redux';
import { Action } from '../../types';
import * as constants from '../constants';
import { ApiError, CombinedState } from '../types';

export const apiError: Reducer<ApiError, Action<any>> = (state = null, action) => {
  switch (action.type) {
    case constants.SET_API_ERROR:
      return action.payload;
    case constants.CLEAR_API_ERROR:
      return null;
    default:
      return state;
  }
};

const reducer: Reducer<CombinedState> = combineReducers({
  apiError,
});

export default reducer;
