import Divider from '@material-ui/core/Divider';
import { dateToString } from 'common/utils/date';
import { ButtonPrimary } from 'components/common/button/ContainedButton';
import { useMatchParams } from 'components/common/hooks';
import useGlobalDialog from 'components/common/hooks/useGlobalDialog';
import PUHeader from 'components/common/pu/Header';
import { nls } from 'locale/language';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import routes from 'routes';
import { Project } from 'store/data/types';
import styled from 'styled-components';
import ProjectPageLayout from '../ProjectPageLayout';
import Form, { Result as FormResult } from './Form';
import { DispatchWrapper } from './mapDispatchToProps';

interface Props extends RouteComponentProps<{ projectId: string }>, DispatchWrapper {
  existing: Project;
  onSubmit(arg: {
    payload;
    onCreated: (proj: Project) => void;
    onUpdated: () => void;
    onUpdateError: () => void;
  });
}

export default function ProjectFormPage({ dispatch, existing, history, onSubmit }: Props) {
  const { showAlert, showModal } = useGlobalDialog();
  const { projectId } = useMatchParams();
  const editing = projectId !== 0 && !!projectId;

  const [validating, setValidating] = useState(false);
  const [dirty, setDirty] = useState(false);

  const onConfirm = () => setValidating(true);

  const onValidated = ({
    title,
    description,
    startDate,
    endDate,
    complete,
    coordinateSystem,
    mapProvider,
    volumeUnitId,
    logoutByDistance,
  }: FormResult) => {
    setValidating(false);

    const onCreated = (proj: Project) => {
      const id = proj?.id;
      const zoneId = proj?.zoneIds?.[0];
      if (!id || !zoneId) return;

      setDirty(false);
      showModal({
        title: nls.projectFormSuccessConfirmTitle(),
        content: nls.projectFormSuccessConfirmMessage(title),
        primaryButtonProps: {
          title: nls.projectFormSuccessConfirmShowProjectListButton(),
          onClick: () => {
            history.push(routes.project.index.path);
          },
        },
        secondaryButtonProps: {
          title: nls.projectFormSuccessConfirmDefaultButton(),
          onClick: () => {
            history.push(routes.snapshot.list.of({ projectId: id, zoneId }));
          },
        },
      });
    };

    const onUpdated = () => {
      setDirty(false);
      showAlert({
        content: nls.projectFormUpdateSuccess(),
        primaryButtonProps: {
          title: nls.confirm(),
          onClick: () => history.push(routes.project.info.of({ projectId })),
        },
      });
    };
    const onUpdateError = () => {
      showAlert({
        content: nls.projectFormUpdateError(),
        primaryButtonProps: { title: nls.confirm(), color: 'secondary' },
      });
    };

    const payload = {
      name: title,
      description:
        description === ''
          ? null /* NOTE: 백엔드에서 빈 문자열 받으면 오류 뱉는 버그 있어 임시 대응 */
          : description,
      construction_date: dateToString(startDate),
      completed_date: dateToString(endDate),
      state: complete,
      coordinate: coordinateSystem,
      mapProvider,
      volumeUnitId,
      data: {
        logout_by_distance: logoutByDistance,
      },
    };
    onSubmit({ payload, onCreated, onUpdated, onUpdateError });
  };

  const footer = (
    <ButtonLayout>
      <ButtonPrimary onClick={onConfirm}>{editing ? nls.save() : nls.add()}</ButtonPrimary>
    </ButtonLayout>
  );

  return (
    <ProjectPageLayout project={existing}>
      <PUHeader
        title={editing ? nls.projectFormHeadingEditing() : nls.projectFormHeadingCreating()}
        shouldConfirm={dirty}
      />
      <Form
        dispatch={dispatch}
        existing={existing}
        validating={validating}
        onFormUpdate={() => {
          setValidating(false);
          setDirty(true);
        }}
        onValidated={onValidated}
      />
      <Divider />
      {footer}
    </ProjectPageLayout>
  );
}

const ButtonLayout = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 3.125rem;
  display: flex;
  justify-content: flex-end;

  & > :not(:first-child) {
    margin-left: 1rem;
  }
`;
