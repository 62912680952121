import { apiAxios } from 'common/api/common';
import { RelationAnnotation, RelationChain } from 'query/relation/types';
import {
  AnnotationType,
  ChainListItem,
  CreateChainType,
  EarthworkCrossingLinesElevations,
  Elevation,
  ElevationDxf,
  PolylineAnnotation,
  UpdateAnnotationType,
  UpdatePolygonType,
  UpdatePositionType,
  Volume,
} from './types';

interface Props {
  zoneId: number;
  snapshotId: number;
  referSnapshotId?: number;
  elevationRelatedSnapshotId?: number;
  annotationType: AnnotationType;
  annotationId: number;
  positionId: number;
  elevationId: number;
  resourceId: number;
  planLevelId: number;
  type: RelationAnnotation['type'];
  data: any;
}

export default {
  async list({ snapshotId, annotationType }: Pick<Props, 'snapshotId' | 'annotationType'>) {
    const response = await apiAxios.get(`/api/v3/snapshots/${snapshotId}/${annotationType}`);
    return response?.data?.results || [];
  },
  async listByZoneId({ zoneId, snapshotId, type }: Pick<Props, 'zoneId' | 'snapshotId' | 'type'>) {
    const response = await apiAxios.get<{ results: RelationAnnotation[] }>(
      `/api/v3/zones/${zoneId}/annotations?type=${type}&snapshot_id=${snapshotId}`,
    );
    return response?.data?.results;
  },
  async create({
    snapshotId,
    annotationType,
    data,
  }: Pick<Props, 'snapshotId' | 'annotationType' | 'data'>) {
    const response = await apiAxios.post(
      `/api/v3/snapshots/${snapshotId}/${changeChainToPolyline(annotationType)}`,
      data,
    );
    return response?.data;
  },
  async read({
    snapshotId,
    annotationType,
    annotationId,
  }: Pick<Props, 'snapshotId' | 'annotationType' | 'annotationId'>) {
    const response = await apiAxios.get(
      `/api/v3/snapshots/${snapshotId}/${
        annotationType === 'chains' ? 'polylines' : annotationType
      }/${annotationId}`,
    );
    return response?.data;
  },
  async update({ zoneId, annotationType, annotationId, data }: UpdateAnnotationType) {
    const response = await apiAxios.patch(
      `/api/v3/zones/${zoneId}/${changeChainToPolyline(annotationType)}/${annotationId}`,
      data,
    );
    return response?.data;
  },
  async delete({
    zoneId,
    annotationType,
    annotationId,
  }: Pick<Props, 'zoneId' | 'annotationType' | 'annotationId'>) {
    const response = await apiAxios.delete(
      `/api/v3/zones/${zoneId}/${changeChainToPolyline(annotationType)}/${annotationId}`,
    );
    return response?.data;
  },
  position: {
    async read({
      annotationType,
      annotationId,
      positionId,
    }: Pick<Props, 'annotationType' | 'annotationId' | 'positionId'>) {
      const response = await apiAxios.get(
        `/api/v3/${annotationType}/${annotationId}/positions/${positionId}`,
      );
      return response?.data;
    },
    async update({ zoneId, annotationType, annotationId, position }: UpdatePositionType) {
      const response = await apiAxios.patch(
        `/api/v3/zones/${zoneId}/${changeChainToPolyline(
          annotationType,
        )}/${annotationId}/positions/${position.id}`,
        position,
      );
      return response?.data;
    },
  },
  elevation: {
    async create({
      snapshotId,
      annotationId,
      data,
    }: Pick<Props, 'snapshotId' | 'annotationId' | 'data'>) {
      const response = await apiAxios.post<Elevation>(
        `/api/v3/snapshots/${snapshotId}/polylines/${annotationId}/elevations`,
        data,
      );
      return response?.data;
    },
    async read({
      elevationRelatedSnapshotId,
      annotationId,
      elevationId,
    }: Pick<Props, 'elevationRelatedSnapshotId' | 'annotationId' | 'elevationId'>) {
      const response = await apiAxios.get<Elevation>(
        `/api/v3/snapshots/${elevationRelatedSnapshotId}/polylines/${annotationId}/elevations/${elevationId}`,
      );
      return response?.data;
    },
    async readCompare({
      snapshotId,
      annotationId,
      referSnapshotId,
    }: Pick<Props, 'snapshotId' | 'annotationId' | 'referSnapshotId'>) {
      const response = await apiAxios.get<Elevation>(
        `/api/v3/snapshots/${snapshotId}/polylines/${annotationId}/elevations/refer-snapshot/${referSnapshotId}`,
      );
      return response?.data;
    },
    async createDxf({
      snapshotId,
      elevationId,
      data,
    }: {
      snapshotId: number;
      elevationId: number;
      data: { referElevationIds: number[] };
    }) {
      const response = await apiAxios.post(
        `/api/v3/snapshots/${snapshotId}/elevations/${elevationId}/elevations-dxf`,
        data,
      );
      return response?.data;
    },
    async readElevationDxf({ snapshotId, dxfFileKey }: { snapshotId: number; dxfFileKey: string }) {
      const response = await apiAxios.get<ElevationDxf>(
        `/api/v3/snapshots/${snapshotId}/elevations-dxf/${dxfFileKey}`,
      );
      return response?.data;
    },
    async readEarthworkCrossingLines({
      snapshotId,
      resourceId,
      planLevelId,
    }: Pick<Props, 'snapshotId' | 'resourceId'> & Partial<Pick<Props, 'planLevelId'>>) {
      const response = await apiAxios.get<EarthworkCrossingLinesElevations>(
        `/api/v3/snapshots/${snapshotId}/resources/${resourceId}/earthwork-crossing-lines/elevations`,
        { params: { plan_level_id: planLevelId } },
      );
      return response?.data;
    },
    async createDxfOfEarthworkCrossingLines({
      snapshotId,
      resourceId,
      planLevelId,
    }: Pick<Props, 'snapshotId' | 'resourceId'> & Partial<Pick<Props, 'planLevelId'>>) {
      const response = await apiAxios.post<{ key: string }>(
        `/api/v3/snapshots/${snapshotId}/resources/${resourceId}/elevations-dxf`,
        { referResourceIds: planLevelId ? [planLevelId] : [] },
      );
      return response?.data;
    },
    async calculateEarthworkCrossingLines({
      snapshotId,
      resourceId,
      planLevelId = null,
    }: Pick<Props, 'snapshotId' | 'resourceId'> & Partial<Pick<Props, 'planLevelId'>>) {
      const response = await apiAxios.post(
        `/api/v3/snapshots/${snapshotId}/resources/${resourceId}/earthwork-crossing-lines/elevations`,
        { planLevelId },
      );
      return response?.data;
    },
  },
  volumes: {
    async create({
      snapshotId,
      polygonId,
      data,
    }: {
      snapshotId: number;
      polygonId: number;
      data:
        | { surfaceType: 'TRIANGULATED' }
        | ({ surfaceType: 'REFER_ALTITUDE' } & Pick<Volume, 'referAltitude'>)
        | ({ surfaceType: 'REFER_SNAPSHOT' } & Pick<Volume, 'referSnapshotId'>)
        | ({ surfaceType: 'REFER_PLAN_LEVEL' } & Pick<Volume, 'referResourceId'>);
    }) {
      const response = await apiAxios.post<Volume>(
        `api/v3/snapshots/${snapshotId}/polygons/${polygonId}/volumes`,
        data,
      );
      return response?.data;
    },
    async read({
      snapshotId,
      polygonId,
      volumeId,
    }: {
      snapshotId: number;
      polygonId: number;
      volumeId: number;
    }) {
      const response = await apiAxios.get<Volume>(
        `/api/v3/snapshots/${snapshotId}/polygons/${polygonId}/volumes/${volumeId}`,
      );
      return response?.data;
    },
    async list({ snapshotId, polygonId }) {
      const response = await apiAxios.get<{ results: Volume[] }>(
        `/api/v3/snapshots/${snapshotId}/polygons/${polygonId}/volumes`,
      );
      return response?.data?.results;
    },
  },
  chain: {
    async create({ snapshotId, data }: { snapshotId: number; data: CreateChainType }) {
      const response = await apiAxios.post(
        `/api/v3/snapshots/${snapshotId}/earthwork-crossing-lines`,
        data,
        { timeout: 3600_000 },
      );
      return response?.data;
    },
    async list({ snapshotId }) {
      const response = await apiAxios.get<{ results: ChainListItem[] }>(
        `/api/v3/snapshots/${snapshotId}/earthwork-crossing-lines`,
      );
      return response?.data?.results;
    },
    async listByZone({ zoneId, snapshotId }) {
      const response = await apiAxios.get<{ results: RelationChain[] }>(
        `/api/v3/zones/${zoneId}/earthwork-crossing-lines?snapshot_id=${snapshotId}`,
      );
      return response?.data?.results;
    },
    async read({ snapshotId, resourceId }) {
      const response = await apiAxios.get<{ results: PolylineAnnotation[] }>(
        `/api/v3/snapshots/${snapshotId}/earthwork-crossing-lines/${resourceId}`,
        { timeout: 60_000 },
      );
      return response?.data?.results;
    },
    async delete({ zoneId, resourceId }) {
      const response = await apiAxios.delete(
        `/api/v3/zones/${zoneId}/earthwork-crossing-lines/${resourceId}`,
      );
      return response?.data;
    },
  },
  polygon: {
    async patch({ zoneId, annotationId, positions }: UpdatePolygonType) {
      const response = await apiAxios.patch(
        `/api/v3/zones/${zoneId}/polygons-reset/${annotationId}`,
        {
          positions,
        },
      );
      return response?.data;
    },
  },
};

const changeChainToPolyline = (type: AnnotationType) => (type === 'chains' ? 'polylines' : type);
