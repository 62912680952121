import { sendEventWhenSecondaryOpen } from 'ga/actions';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import routes from 'routes';
import { RightSideMenuType, SecondaryMenuType, SideMenuExpandLevel } from '../types';

interface MenuValue {
  expandLevel: SideMenuExpandLevel;
  secondaryMenuType: SecondaryMenuType;
  rightMenuType: RightSideMenuType;
  showingCctvPreview?: boolean;
}
interface MenuActions {
  toggleSideMenu: () => void;
  openSecondarySideMenu: (type: SecondaryMenuType, mapType: '2d' | '3d') => void;
  openRightSideMenu: (type: RightSideMenuType) => void;
  closeSecondarySideMenu: () => void;
  closeRightSideMenu: () => void;
  toggleCctvPreview: (open: boolean) => void;
}
const INITIAL_EXPAND_LEVEL = 1;
const MenuValueContext = createContext<MenuValue>({
  expandLevel: INITIAL_EXPAND_LEVEL,
  secondaryMenuType: null,
  rightMenuType: null,
});
const MenuActionsContext = createContext<MenuActions>(null);

export default function MenuProvider({ children }: { children: React.ReactNode }) {
  const match = useRouteMatch();
  const [expandLevel, setExpandLevel] = useState<SideMenuExpandLevel>(INITIAL_EXPAND_LEVEL);
  const [secondaryMenuType, setSecondaryMenuType] = useState<SecondaryMenuType>(null);
  const [rightMenuType, setRightMenuType] = useState<RightSideMenuType>(null);
  const [showingCctvPreview, setShowingCctvPreview] = useState<boolean>(true);

  const disabledRightSideMenu =
    match.path === routes.earthwork.detailTable.path ||
    match.path === routes.earthwork.summaryTable.path;

  const menuActions: MenuActions = useMemo(
    () => ({
      toggleSideMenu: () => setExpandLevel((prev) => (prev === 0 ? 1 : 0)),
      openSecondarySideMenu: (type, mapType = '2d') => {
        setSecondaryMenuType(type);
        setExpandLevel((prev) => (prev === 1 ? 2 : prev));
        sendEventWhenSecondaryOpen(type, mapType);
      },
      openRightSideMenu: (type: RightSideMenuType) => {
        if (disabledRightSideMenu) return;

        setExpandLevel((prev) => (prev === 2 ? 1 : prev));
        setRightMenuType(type);
      },
      closeSecondarySideMenu: () => {
        setExpandLevel((prev) => (prev === 2 ? 1 : prev));
        setSecondaryMenuType(null);
      },
      closeRightSideMenu: () => {
        setRightMenuType(null);
      },
      toggleCctvPreview: (open: boolean) => {
        setShowingCctvPreview(open);
      },
    }),
    [disabledRightSideMenu],
  );

  const menuValue: MenuValue = useMemo(
    () => ({
      expandLevel,
      secondaryMenuType,
      rightMenuType: disabledRightSideMenu ? null : rightMenuType,
      showingCctvPreview,
    }),
    [expandLevel, secondaryMenuType, rightMenuType, showingCctvPreview, disabledRightSideMenu],
  );

  return (
    <MenuActionsContext.Provider value={menuActions}>
      <MenuValueContext.Provider value={menuValue}>{children}</MenuValueContext.Provider>
    </MenuActionsContext.Provider>
  );
}

export const useMenuValueContext = () => useContext(MenuValueContext);
export const useMenuActionsContext = () => useContext(MenuActionsContext);
