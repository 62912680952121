import { hexToRgba } from './utils';

const PRIMARY = '#1D2125';
const SECONDARY = hexToRgba(PRIMARY, 0.6);
const DISABLED = hexToRgba(PRIMARY, 0.3);

export const text = {
  PRIMARY,
  SECONDARY,
  DISABLED,
};
