import { apiAxios } from 'common/api/common';
import { Zone } from 'query/zone/types';

export default {
  async create({ projectId, data }: { projectId: number; data: Pick<Zone, 'name'> }) {
    const response = await apiAxios.post<Zone>(`/api/v3/projects/${projectId}/zones`, data);
    return response.data;
  },
  async read({ projectId, zoneId }: { projectId: number; zoneId: number }) {
    const response = await apiAxios.get<Zone>(`/api/v3/projects/${projectId}/zones/${zoneId}`);
    return response.data;
  },
  async list({ projectId }: { projectId: number }) {
    const response = await apiAxios.get<{ results: Zone[] }>(`/api/v3/projects/${projectId}/zones`);
    return response.data?.results;
  },
  async update({
    zoneId,
    projectId,
    data,
  }: {
    zoneId: number;
    projectId: number;
    data: Pick<Zone, 'name'>;
  }) {
    const response = await apiAxios.patch(`/api/v3/projects/${projectId}/zones/${zoneId}`, data);
    return response.data;
  },
  async delete({ projectId, zoneId }: { projectId: number; zoneId: number }) {
    const response = await apiAxios.delete(`/api/v3/projects/${projectId}/zones/${zoneId}`);
    return response.data;
  },
};
