import { ApiResponse } from 'apisauce';
import { ErrorMessageCode } from 'common/types';
import { call, put } from 'redux-saga/effects';
import * as actions from 'store/data/actions';
import { Action } from 'store/types';
import { convertKeysToCamelCase, convertKeysToSnakeCase } from './utils';

// 추가 action 들을 실행하는 function
export function* executeNextAction(...action: Action[]) {
  const actionLength = action.length;

  for (let i = 0; i < actionLength; i += 1) {
    yield put(action[i]);
  }
}

// server api 호출
export default function* requestApi<RESPONSE = any, PAYLOAD extends object = any>(
  apiFunc: (payload?: PAYLOAD) => Promise<ApiResponse<RESPONSE>>,
  payload: PAYLOAD,
  options?: { turnOffCaseConversion?: boolean; turnOffCommonErrorHandling?: boolean },
) {
  let response;
  if (payload != null) {
    const args = !options?.turnOffCaseConversion ? getConvertedParams(payload) : payload;
    response = yield call(apiFunc, args);
  } else {
    response = yield call(apiFunc);
  }

  const { ok, status, ...body } = response;

  const responseData = !options?.turnOffCaseConversion
    ? convertKeysToCamelCase({ ...body.data })
    : { ...body.data };
  const isFail = !ok;

  if (isFail && !options?.turnOffCommonErrorHandling) {
    yield processForApiError(responseData, status, body, ok);
  }
  return { isFail, responseData };
}

function* processForApiError(responseData: any, status: any, body: any, ok: any) {
  const errorCode = responseData.errorCode || responseData.error_code;
  if (errorCode) {
    yield put(actions.setApiError({ messageCode: errorCode, status, config: body.config }));
  } else if (!ok && body.problem !== 'CANCEL_ERROR') {
    // 일부러 취소한 것이기 때문에 사용자에게 보여줄 필요없다고 판단
    yield put(actions.setApiError({ messageCode: body.problem, status, config: {} }));
  } else {
    yield put(
      actions.setApiError({ messageCode: ErrorMessageCode.SERVER_FAIL_CODE, status, config: {} }),
    );
  }
}

function getConvertedParams(payload: object) {
  const params = convertKeysToSnakeCase({ ...payload });
  return params;
}
