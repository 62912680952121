import { AxiosError } from 'axios';
import { DEFAULT_POLLING_INTERVAL, DEFAULT_QUERY_STALE_TIME } from 'common/policies/request';
import { Error } from 'common/types';
import { requestUploadFile, s3DirectFileUpload } from 'query/fileUpload/s3Upload';
import { S3FileUploadType } from 'query/fileUpload/types';
import { Permission } from 'query/permission/types';
import { TempFile } from 'query/resource/types';
import { useState } from 'react';
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import { ISSUE_COLORS } from 'styles/colors/symbols';
import api from './api';
import { keys } from './queryKeys';
import {
  Comment,
  CreateCommentType,
  EditingIssueType,
  Issue,
  IssueResource,
  Vi,
  getIssueFileType,
} from './types';

export const DEFAULT_CREATE_ISSUE_DATA: EditingIssueType = {
  name: '',
  description: '',
  status: 'RUNNING',
  positions: [],
  color: ISSUE_COLORS.RUNNING,
  tempFile: null,
  positionsDirty: true,
  dirty: true,
  id: 'new',
};

export function useIssueListQuery(
  { zoneId, status, type, permission }: Parameters<typeof api.list>[0] & { permission: Permission },
  options?: UseQueryOptions<Issue[], AxiosError<Error>>,
) {
  const { enabled = true, ...rest } = options || {};
  return useQuery<Issue[]>(
    keys.list(zoneId, type, status),
    () => api.list({ zoneId, type, status }),
    {
      enabled: Boolean(zoneId && permission?.issueGet && enabled),
      staleTime: DEFAULT_QUERY_STALE_TIME,
      ...rest,
    },
  );
}

export function useIssueQuery(
  zoneId: number,
  issueId: number | 'new',
  options?: UseQueryOptions<Issue, AxiosError<Error>>,
) {
  const { enabled = true, ...rest } = options || {};
  if (issueId === 'new') {
    return useQuery<EditingIssueType>(keys.read(zoneId, 'new'), {
      initialData: DEFAULT_CREATE_ISSUE_DATA as Issue,
      enabled: Boolean(zoneId && enabled),
      staleTime: Infinity,
    });
  }
  return useQuery<EditingIssueType>(
    keys.read(zoneId, issueId),
    () => api.read({ zoneId, issueId }),
    {
      enabled: Boolean(zoneId && issueId && enabled),
      staleTime: DEFAULT_QUERY_STALE_TIME,
      ...rest,
    },
  );
}

export function useCommentListQuery(
  zoneId: number,
  issueId: number,
  options?: UseQueryOptions<Comment[], AxiosError<Error>>,
) {
  const { enabled = true, ...rest } = options || {};
  return useQuery<Comment[]>(
    keys.comment.list(zoneId, issueId),
    () => api.comment.list({ zoneId, issueId }),
    {
      enabled: Boolean(zoneId && issueId && enabled),
      staleTime: DEFAULT_QUERY_STALE_TIME,
      ...rest,
    },
  );
}

export function useCommentQuery(
  zoneId: number,
  issueId: number,
  commentId: number,
  options?: UseQueryOptions<Comment, AxiosError<Error>>,
) {
  const { enabled = true, ...rest } = options || {};
  return useQuery(
    keys.comment.read(zoneId, issueId, commentId),
    () => api.comment.read({ zoneId, issueId, commentId }),
    {
      enabled: Boolean(zoneId && issueId && commentId && enabled),
      staleTime: DEFAULT_QUERY_STALE_TIME,
      ...rest,
    },
  );
}

export function useIssueMutation({ zoneId }: { zoneId: number }) {
  const queryClient = useQueryClient();

  const createIssue = useMutation(
    async ({ data }: { data: Omit<EditingIssueType, 'id'> }) => {
      const { tempFile, ...rest } = data;
      const tempFiles = tempFile ? [tempFile] : [];
      const resources = await getResourcesAfterS3Upload(tempFiles);
      return api.create({
        zoneId,
        data: { ...rest, resources },
      });
    },
    {
      onSuccess: () => queryClient.invalidateQueries(keys.list(zoneId)),
    },
  );

  const updateIssue = useMutation(
    async ({ issueId, data }: { issueId: number; data: Partial<EditingIssueType> }) => {
      const isDeleteImage = !data?.tempFile && data?.resources?.length === 0;
      const tempFiles = data?.tempFile ? [data?.tempFile] : [];
      const newResources = tempFiles.length
        ? await getResourcesAfterS3Upload(tempFiles)
        : undefined;
      const resources = isDeleteImage ? [] : newResources;

      return api.update({
        zoneId,
        issueId,
        data: {
          ...data,
          resources,
          tempFile: undefined,
        },
      });
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(keys.all());
        queryClient.invalidateQueries(keys.comment.list(zoneId, variables?.issueId));
      },
    },
  );

  const deleteIssue = useMutation(
    ({ issueId }: { issueId: number }) => api.delete({ zoneId, issueId }),
    {
      onSuccess: () => queryClient.invalidateQueries(keys.list(zoneId)),
    },
  );

  return { createIssue, updateIssue, deleteIssue };
}

export function useIssuePositionMutation() {
  const queryClient = useQueryClient();

  const updateIssuePosition = useMutation(
    ({ zoneId, issueId, data }: Parameters<typeof api.position.update>[0]) =>
      api.position.update({ zoneId, issueId, data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(keys.all());
      },
    },
  );

  return { updateIssuePosition };
}

export function useCommentMutation({ zoneId, issueId }: { zoneId: number; issueId: number }) {
  const queryClient = useQueryClient();

  const createComment = useMutation(
    async ({ data }: { data: Partial<CreateCommentType> }) => {
      const { tempFiles, ...rest } = data;
      const resources = await getResourcesAfterS3Upload(tempFiles);
      return api.comment.create({
        zoneId,
        issueId,
        data: {
          ...rest,
          resources,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(keys.comment.list(zoneId, issueId));
      },
    },
  );

  const updateComment = useMutation(
    ({ commentId, data }: { commentId: number; data: Partial<Comment> }) =>
      api.comment.update({ zoneId, issueId, commentId, data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(keys.comment.list(zoneId, issueId));
      },
    },
  );

  const deleteComment = useMutation(
    ({ commentId }: { commentId: number }) => api.comment.delete({ zoneId, issueId, commentId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(keys.comment.list(zoneId, issueId));
      },
    },
  );

  return { createComment, updateComment, deleteComment };
}

async function getResourcesAfterS3Upload(tempFiles: TempFile[]): Promise<IssueResource[]> {
  if (!tempFiles?.length) return [];
  const resources = await Promise.all(
    tempFiles.map(async (x) => {
      const { data: dataForUploads } = await requestUploadFile({
        name: x.name,
        size: x.size,
      });
      const { fields } = dataForUploads as S3FileUploadType;
      await s3DirectFileUpload(dataForUploads, x.file);
      return {
        resource: {
          file: fields.key,
          fileName: x.name,
          fileType: getIssueFileType(x.name.split('.').pop()),
        },
      };
    }),
  );
  return resources as IssueResource[];
}

export function useIssueViQuery(
  { snapshotId, issueId, data }: Parameters<typeof api.vi.create>[0],
  options?: UseQueryOptions<Vi, AxiosError<Error>>,
) {
  const queryClient = useQueryClient();
  const { enabled = true, ...rest } = options || {};
  const [ids, setIds] = useState<{ resourceId: number; viId: number }>(undefined);

  const polling = Boolean(ids?.resourceId === data?.resourceId && ids?.viId);

  return useQuery(
    keys.vi.read(snapshotId, issueId, data?.resourceId),
    () =>
      polling
        ? api.vi.read({ snapshotId, issueId, id: ids?.viId })
        : api.vi.create({ snapshotId, issueId, data }),
    {
      enabled: Boolean(snapshotId && issueId && data?.resourceId && enabled),
      staleTime: DEFAULT_QUERY_STALE_TIME,
      onSuccess: (res) => {
        res?.id && setIds({ resourceId: data?.resourceId, viId: res?.id });
      },
      onError: (err) => {
        queryClient.setQueryData(keys.vi.read(snapshotId, issueId, data?.resourceId), {
          errorCode: err?.response?.data?.errorCode,
        });
      },
      refetchInterval(res) {
        if (res?.calculationStatus === 'IN_PROGRESS') {
          return DEFAULT_POLLING_INTERVAL;
        }
        return false;
      },
      ...rest,
    },
  );
}

export function useIssueViMutation() {
  const queryClient = useQueryClient();
  const updateVi = useMutation(
    ({
      snapshotId,
      issueId,
      id,
      data,
    }: Parameters<typeof api.vi.update>[0] & { resourceId: number }) =>
      api.vi.update({ snapshotId, issueId, id, data }),
    {
      onSuccess: (_, v) => {
        queryClient.invalidateQueries(keys.vi.read(v?.snapshotId, v?.issueId, v?.resourceId));
      },
    },
  );
  return { updateVi };
}
