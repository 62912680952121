import { Typography } from '@material-ui/core';
import { sendTwoDEarthworkZoneEditorZoneDelBtn } from 'ga/actions/twoDEarthworkZone';
import { LatLng } from 'leaflet';
import { nls } from 'locale/language';
import React from 'react';
import EditorPageLayout from 'styleComponents/editorPageLayout';
import CoordinateEditor from 'styleComponents/editorPageLayout/CoordinateEditor';
import ListStartIcon from 'styleComponents/icon/ListStartIcon';
import styled from 'styled-components';
import theme from 'styles/mui/theme';
import { layerIcons } from '../detailsPage/sideMenu/common/layerUtils';
import useDrawingVectorEdit from './useDrawingVectorEdit';

const COORDINATE_DECIMAL_PLACES = 3;

export default function DrawingVectorEditorRightSideBar() {
  const {
    bulkPolygons,
    setSelectedBulkPolygonTempId,
    selectedBulkPolygonTempId,
    updateBulkPolygon,
    deleteBulkPolygon,
  } = useDrawingVectorEdit();

  const selectedBulkPolygon = bulkPolygons.find((x) => x.tempId === selectedBulkPolygonTempId);

  const coordinates = selectedBulkPolygon?.positionList?.map((x, index) => ({
    id: index + 1,
    x: x.longitude,
    y: x.latitude,
  }));

  function handleCoordinates(index: number, key: 'x' | 'y', value: number) {
    if (!selectedBulkPolygonTempId) return;
    const position = coordinates[index];
    const newPosition = { ...position, [key]: value };
    const newPositions = coordinates.map((x) => (x.id === index + 1 ? newPosition : x));
    const latlngs = newPositions.map((x) => ({ lat: x.y, lng: x.x })) as LatLng[];
    updateBulkPolygon(selectedBulkPolygonTempId, latlngs);
  }

  function handleDelete() {
    if (selectedBulkPolygonTempId) {
      deleteBulkPolygon(selectedBulkPolygonTempId);
      sendTwoDEarthworkZoneEditorZoneDelBtn();
    }
  }

  const measure = selectedBulkPolygon?.data?.size
    ? `${nls.earthworkTableHeadAnnotation()} ${selectedBulkPolygon?.data?.size?.toFixed(
        COORDINATE_DECIMAL_PLACES,
      )} m²`
    : '';
  return (
    <EditorPageLayout.RightSideBar
      open={Boolean(selectedBulkPolygonTempId)}
      title={nls.basicMoreInfo()}
      onClose={() => setSelectedBulkPolygonTempId(false)}
      onDelete={handleDelete}
    >
      <RightSideBarContent>
        <RightSideBarContentTitle>
          <ListStartIcon color={selectedBulkPolygon?.color}>{layerIcons.polygons}</ListStartIcon>
          <Typography variant="h6" color="inherit">
            {selectedBulkPolygon?.name}
          </Typography>
        </RightSideBarContentTitle>
        <CoordinateEditor
          title={nls.positionAreaCoordinateSystem()}
          coordinates={coordinates}
          measure={measure}
          handleCoordinates={handleCoordinates}
          decimalPlaces={COORDINATE_DECIMAL_PLACES}
        />
      </RightSideBarContent>
    </EditorPageLayout.RightSideBar>
  );
}

const RightSideBarContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1.25rem;
  gap: 2.5rem;
`;

const RightSideBarContentTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${theme.palette.grey[800]};
  gap: 0.5rem;
`;
