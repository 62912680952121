import { Api } from 'common/api';
import { applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import legacyReducer from './data/reducers';
import { State } from './types';
import v2 from './v2';

export type Deps = {
  api: Api;
};
type LegacyDeps = Api;
type CompatibleDeps = Deps & LegacyDeps;

export default function create(deps: Deps | LegacyDeps): Store<State> {
  const rootReducer = combineReducers<State>({
    data: legacyReducer,
    v2: combineReducers({
      apiError: v2.apiError.reducer,
      snapshot: v2.snapshot.reducer,
      drawing: v2.drawing.reducer,
      aerialImage: v2.aerialImage.reducer,
      resource: v2.resource.reducer,
    }),
  });

  const sagas: ((x: CompatibleDeps) => Generator)[] = [
    ...Object.values(v2)
      .map((x) => x.sagas)
      .filter((x) => x),
  ];
  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers =
    (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] &&
      window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']({ trace: true })) ||
    compose;
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

  const compatibleDeps = isLegacyDeps(deps)
    ? ({ ...deps, api: deps } as CompatibleDeps)
    : ({ ...deps.api, ...deps } as CompatibleDeps);
  sagas.forEach((saga) => sagaMiddleware.run(saga, compatibleDeps));

  return store;
}

function isLegacyDeps(deps: Deps | LegacyDeps): deps is LegacyDeps {
  return (deps as Deps).api === undefined;
}
