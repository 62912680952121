import { Reducer } from 'redux';
import { ActionType } from './actions';
import constants from './constants';
import { SnapshotData } from './types';

const reducer: Reducer<
  SnapshotData,
  ActionType<'setVisibility'> | ActionType<'setOpacity'> | ActionType<'setViewport'>
> = (
  state = {
    visibilities: {
      planLevel3D: {},
      dsm3D: {},
      bim: {},
      bimOnPointCloud: {},
    },
    opacities: {
      dsm: undefined,
      planLevel: {},
      stratums: {},
      planLevel3D: {},
      drawings: {},
      dsm3D: {},
      bimOnPointCloud: {},
    },
    viewport: null,
  },
  action,
) => {
  switch (action.type) {
    case constants.SET_VISIBILITY: {
      const { payload } = action as ActionType<'setVisibility'>;
      const { type, id, visible } = payload;
      const visibilities = {
        ...state.visibilities,
        [type]:
          id && typeof state.visibilities[type] === 'object'
            ? { ...(state.visibilities[type] as {}), [id]: visible }
            : visible,
      };
      return {
        ...state,
        visibilities,
      };
    }
    case constants.SET_OPACITY: {
      const { payload } = action as ActionType<'setOpacity'>;
      const { type, id, opacity } = payload;
      const opacities = {
        ...state.opacities,
        [type]:
          id && typeof state.opacities[type] === 'object'
            ? { ...(state.opacities[type] as {}), [id]: opacity }
            : opacity,
      };
      return {
        ...state,
        opacities,
      };
    }
    case constants.SET_VIEWPORT: {
      const { payload } = action as ActionType<'setViewport'>;
      if (!payload) {
        // 값이 없을 경우에는 viewport 초기화
        return { ...state, viewport: null };
      }
      // 객체 하위에 값이 하나 이상 있을 경우 id제외한 나머지는 기존값 유지하도록 수정
      return {
        ...state,
        viewport: { ...state?.viewport, ...payload },
      };
    }
    default:
      return state;
  }
};

export default reducer;
