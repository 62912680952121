import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { useMapRotationTooltip } from 'components/snapshot/detailsPage/map/2d/useMapRotation';
import { useAtom } from 'jotai';
import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { bearingAtom } from 'store/v3/2dMap/map';
import styled from 'styled-components';
import theme from 'styles/mui/theme';
import { useMatchParams } from '../hooks/useMatchParams';

interface Props {
  hidden?: boolean;
}

export default function RotationInputControl({ hidden = false }: Props) {
  const map = useMap();
  const { zoneId } = useMatchParams();
  const [bearing, setBearing] = useAtom(bearingAtom);
  const { initializeTooltip, showTooltip, hideTooltip } = useMapRotationTooltip();

  useEffect(() => {
    map.on('rotate', () => {
      const integerValue = Math.round(map.getBearing());
      setBearing({ zoneId, value: integerValue });
    });
    initializeTooltip();
    return () => {
      // setBearing({ zoneId: undefined, value: 0 }); // @todo: 화면을 나가더라도 회전 유지하게끔 하려고 주석처리. 기획 컨펌 받아야 함
      map.off('rotate');
    };
  }, []);

  function onChange(e) {
    const v = Number(e.target.value);
    if (isNaN(v) || v < 0 || v > 360) return;
    map.setBearing(v);
  }

  const endAdornment = <StyledInputAdornment position="end">°</StyledInputAdornment>;
  const InputProps = { endAdornment };
  return (
    <StyledInput
      value={bearing.zoneId == zoneId ? bearing.value : 0}
      hidden={hidden}
      onChange={onChange}
      onBlur={hideTooltip}
      onFocus={showTooltip}
      InputProps={InputProps}
    />
  );
}

const StyledInput = styled(TextField).attrs({ variant: 'outlined' })`
  position: absolute;
  left: 1rem;
  top: 4.5rem;
  z-index: 1100;
  background-color: #fff;
  border-radius: 0.25rem;
  width: 3rem;

  input {
    text-align: right;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.57;
    padding: 0;
  }

  :before {
    border: none !important;
  }
  .MuiOutlinedInput-root {
    min-height: 1.75rem;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${theme.palette.primary.main};
  }
`;
const StyledInputAdornment = styled(InputAdornment)`
  margin-left: 0;
  color: ${theme.palette.primary.contrastText};
  font-size: 0.875rem;
  line-height: 1.57;
  font-weight: 600;
  margin-right: 0.375rem;
`;
