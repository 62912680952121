import GA from 'ga';
import { ANALYSIS_GCP } from 'ga/category';

/** GCP 자동 추천 */
const ANALYSIS_GCP_MRK_AUTO_GCP_BTN = `${ANALYSIS_GCP}MrkAutoGCPBtn`;
/** GCP 자동 추천 취소 */
const ANALYSIS_GCP_MRK_AUTO_GCP_BTN_PNDG_CNCLE = `${ANALYSIS_GCP}MrkAutoGCPBtnPndgCncle`;

/** GCP 자동 추천 */
export function sendAnalysisGcpMrkAutoGcpBtn() {
  GA.event(ANALYSIS_GCP, ANALYSIS_GCP_MRK_AUTO_GCP_BTN);
}
/** GCP 자동 추천 취소 */
export function sendAnalysisGcpMrkAutoGcpBtnPndgCncle() {
  GA.event(ANALYSIS_GCP, ANALYSIS_GCP_MRK_AUTO_GCP_BTN_PNDG_CNCLE);
}
