import Axios, { CancelTokenSource } from 'axios';
import { Api } from 'common/api';
import requestApi from 'common/requestApi';
import { channel, Channel } from 'redux-saga';
import { all, call, CallEffect, cancelled, fork, put, take } from 'redux-saga/effects';
import { SnapshotFileType } from '../types';

export function* getS3DirectUploadUrl(
  api: Api,
  originFileName: string,
  fileSize: number,
  type?: SnapshotFileType,
  snapshotId?: number,
) {
  const queryString =
    type === SnapshotFileType.STRATUM ? `?type=STRATUM&snapshot_id=${snapshotId}` : '';

  return yield requestApi(
    api.file.uploadFiles,
    { filename: originFileName, size: fileSize, type, snapshotId, query: queryString },
    {
      turnOffCaseConversion: true,
      turnOffCommonErrorHandling: type !== SnapshotFileType.STRATUM,
    },
  );
}

type S3FileUploadType = NormalFileType | LargeFileType;
type NormalFileType = {
  url: string;
  fields: { [key: string]: string };
};
type LargeFileType = {
  urls: string[];
  complete_multipart_upload_url: string;
  fields: { [key: string]: string };
};
export function* s3DirectFileUpload(api: Api, resData: S3FileUploadType, file: File) {
  if (!isLargeFile(resData) && !isNormalFile(resData)) {
    return { isFail: true };
  }

  if (isNormalFile(resData)) {
    return yield* singleFileUpload(api, resData, file);
  }

  try {
    return yield* multipartUpload(api, resData, file);
  } catch (e) {
    return { isFail: true };
  }
}

function isNormalFile(x: S3FileUploadType): x is NormalFileType {
  return !!(<NormalFileType>x)?.url && !!x?.fields;
}
function isLargeFile(x: S3FileUploadType): x is LargeFileType {
  return !!(<LargeFileType>x)?.urls && !!(<LargeFileType>x)?.complete_multipart_upload_url;
}

const DEFAULT_CHUNK_SIZE_IN_MB = 4 * 1024 * 1024;

function* singleFileUpload(api, resData: NormalFileType, file: File) {
  const source = Axios.CancelToken.source();
  const options = { turnOffCaseConversion: true, turnOffCommonErrorHandling: true };
  const { url, fields } = resData;
  try {
    const { isFail } = yield requestApi(
      api.file.directUploadToS3,
      { url, fields, file, cancelToken: source.token },
      options,
    );
    return { isFail, resFileName: fields?.key };
  } finally {
    if (yield cancelled() && source) {
      yield call(source.cancel);
    }
  }
}

function* multipartUpload(api, resData: LargeFileType, file: File) {
  const options = { turnOffCaseConversion: true, turnOffCommonErrorHandling: true };
  const { urls, complete_multipart_upload_url: url, fields } = resData;
  const data = yield* uploadParts(file, urls);

  const { isFail } = yield requestApi(api.file.completeMultipartUpload, { url, data }, options);
  return { isFail, resFileName: fields?.Key };
}

function* uploadParts(file: File, urls: string[]) {
  const urlCount = urls.length;
  const fileChunkSize = Math.ceil(file.size / urlCount) || DEFAULT_CHUNK_SIZE_IN_MB;
  const axios = Axios.create();
  const source = Axios.CancelToken.source();

  const tasks = [];
  for (let i = 0; i < urls.length; i += 1) {
    const url = urls[i];
    const start = i * fileChunkSize;
    const end = (i + 1) * fileChunkSize;

    const blob = i < urlCount ? file.slice(start, end) : file.slice(start);
    tasks.push(call(axios.put, url, blob, { cancelToken: source.token }));
  }

  const concurrency = 3;
  const taskChan = yield call(channel);
  const resChan = yield call(channel);
  for (let i = 0; i < concurrency; i += 1) {
    yield fork(handleUpload, taskChan, resChan);
  }
  const requests = [];
  for (let i = 0; i < tasks.length; i += 1) {
    requests.push(put(taskChan, [i, tasks[i], source]));
  }
  yield all(requests);

  const responses = [];
  for (let i = 0; i < tasks.length; i += 1) {
    const [id, header] = yield take(resChan);
    responses.push([id, header]);
  }

  taskChan.close();
  resChan.close();

  const results = responses
    .sort((a, b) => a[0] - b[0])
    .map(([id, header]) => {
      return `<Part><PartNumber>${id + 1}</PartNumber>
      <ETag>${header?.etag}</ETag></Part>`;
    });
  return `<CompleteMultipartUpload>${results.join('')}</CompleteMultipartUpload>`;
}

function* handleUpload(
  taskChan: Channel<[number, CallEffect, CancelTokenSource]>,
  resChan: Channel<[number, {}]>,
) {
  let source: CancelTokenSource;
  try {
    while (true) {
      const task = yield take(taskChan);
      const [id, payload] = task;
      [, , source] = task;
      const { headers } = yield payload;
      yield put(resChan, [id, headers]);
    }
  } finally {
    if (yield cancelled() && source) {
      yield call(source.cancel);
    }
  }
}
