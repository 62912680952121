import { apiAxios } from 'common/api/common';
import { Position } from 'query/annotation/types';
import { Comment, CreateCommentType, EditingIssueType, Issue, Vi } from './types';

export default {
  async list({
    zoneId,
    type,
    status,
  }: {
    zoneId: number;
    type?: Issue['type'] | undefined;
    status?: Issue['status'];
  }) {
    const response = await apiAxios.get<{ results: Issue[] }>(`/api/v3/zones/${zoneId}/issues`, {
      params: { type, status },
    });
    return response.data?.results;
  },
  async create({ zoneId, data }: { zoneId: number; data: Partial<Issue> }) {
    const response = await apiAxios.post<Issue>(`/api/v3/zones/${zoneId}/issues`, data);
    return response.data;
  },
  async read({ zoneId, issueId }: { zoneId: number; issueId: number }) {
    const response = await apiAxios.get<Issue>(`/api/v3/zones/${zoneId}/issues/${issueId}`);
    return response.data;
  },
  async update({
    zoneId,
    issueId,
    data,
  }: {
    zoneId: number;
    issueId: number;
    data: Partial<EditingIssueType>;
  }) {
    const response = await apiAxios.patch<Issue>(`/api/v3/zones/${zoneId}/issues/${issueId}`, data);
    return response.data;
  },
  async delete({ zoneId, issueId }: { zoneId: number; issueId: number }) {
    const response = await apiAxios.delete(`/api/v3/zones/${zoneId}/issues/${issueId}`);
    return response.data;
  },
  position: {
    async update({
      zoneId,
      issueId,
      data,
    }: {
      zoneId: number;
      issueId: number;
      data: { positions: Partial<Position>[] };
    }) {
      const response = await apiAxios.patch<Issue['positions']>(
        `/api/v3/zones/${zoneId}/issues/${issueId}/positions`,
        data,
      );
      return response.data;
    },
  },
  comment: {
    async list({ zoneId, issueId }: { zoneId: number; issueId: number }) {
      const response = await apiAxios.get<{ results: Comment[] }>(
        `/api/v3/zones/${zoneId}/issues/${issueId}/comments`,
      );
      return response.data?.results;
    },
    async create({
      zoneId,
      issueId,
      data,
    }: {
      zoneId: number;
      issueId: number;
      data: Partial<CreateCommentType>;
    }) {
      const response = await apiAxios.post<Comment>(
        `/api/v3/zones/${zoneId}/issues/${issueId}/comments`,
        data,
        { timeout: 0 },
      );
      return response.data;
    },
    async read({
      zoneId,
      issueId,
      commentId,
    }: {
      zoneId: number;
      issueId: number;
      commentId: number;
    }) {
      const response = await apiAxios.get<Comment>(
        `/api/v3/zones/${zoneId}/issues/${issueId}/comments/${commentId}`,
      );
      return response.data;
    },
    async update({
      zoneId,
      issueId,
      commentId,
      data,
    }: {
      zoneId: number;
      issueId: number;
      commentId: number;
      data: Partial<Comment>;
    }) {
      const response = await apiAxios.patch<Comment>(
        `/api/v3/zones/${zoneId}/issues/${issueId}/comments/${commentId}`,
        data,
      );
      return response.data;
    },
    async delete({
      zoneId,
      issueId,
      commentId,
    }: {
      zoneId: number;
      issueId: number;
      commentId: number;
    }) {
      const response = await apiAxios.delete(
        `/api/v3/zones/${zoneId}/issues/${issueId}/comments/${commentId}`,
      );
      return response.data;
    },
  },
  vi: {
    async create({
      snapshotId,
      issueId,
      data,
    }: {
      snapshotId: number;
      issueId: number;
      data: { resourceId: number };
    }) {
      const response = await apiAxios.post<Vi>(
        `/api/v3/snapshots/${snapshotId}/issues/${issueId}/vegetation-index`,
        data,
      );
      return response.data;
    },
    async read({ snapshotId, issueId, id }: { snapshotId: number; issueId: number; id: number }) {
      const response = await apiAxios.get<Vi>(
        `/api/v3/snapshots/${snapshotId}/issues/${issueId}/vegetation-index/${id}`,
      );
      return response.data;
    },
    async update({
      snapshotId,
      issueId,
      id,
      data,
    }: {
      snapshotId: number;
      issueId: number;
      id: number;
      data: { calculationStatus: Vi['calculationStatus'] };
    }) {
      const response = await apiAxios.patch<Vi>(
        `/api/v3/snapshots/${snapshotId}/issues/${issueId}/vegetation-index/${id}`,
        data,
      );
      return response.data;
    },
  },
};
