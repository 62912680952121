const createConstant = (id) => `v2/drawing/${id}`;

export default {
  CREATE_DRAWINGS: createConstant('createDrawing'),
  LIST_DRAWINGS: createConstant('listDrawings'),
  READ_DRAWING: createConstant('readDrawing'),
  UPDATE_POSITIONING_DRAWING: createConstant('updateDrawing'),
  DELETE_DRAWING: createConstant('deleteDrawing'),
  ABORT_DRAWING: createConstant('abortDrawing'),
  RETRY_DRAWING: createConstant('retryDrawing'),
  CANCEL_LIST_DRAWINGS: createConstant('cancelListDrawings'),
  CANCEL_CREATE_DRAWINGS: createConstant('cancelCreateDrawings'),
  SET_DRAWING: createConstant('setDrawing'),
  SET_DRAWINGS: createConstant('setDrawings'),
  SET_VECTOR_LAYERS: createConstant('setDrawingLayerStatus'),
  INITIALIZE_VECTOR_LAYERS: createConstant('initializeVectorLayers'),
  SET_UPLOADING_DRAWINGS: createConstant('setUploadingDrawings'),
  SET_UPLOADING_DRAWING: createConstant('setUploadingDrawing'),
};
