import { SecondaryMenuType } from 'components/snapshot/detailsPage/types';
import { sendThreeDCompareBimLinkBtn, sendThreeDComparePlanLevelLinkBtn } from './threeDCompare';
import {
  sendTwoDCompareDrawingLinkBtn,
  sendTwoDCompareEarthLevelLinkBtn,
  sendTwoDComparePlanLevelLinkBtn,
} from './twoDCompare';
import { sendTwoDEtcLinkBtn } from './twoDEtc';
import {
  sendTwoDToolPointLinkBtn,
  sendTwoDToolPolygonLinkBtn,
  sendTwoDToolPolylineLinkBtn,
} from './twoDTool';

export * from './analysisGcp';
export * from './analysisStart';
export * from './archive';
export * from './birdsEyeView';
export * from './download';
export * from './earthworkTable';
export * from './indoorData';
export * from './location';
export * from './panorama';
export * from './print';
export * from './project';
export * from './slider';
export * from './snapshot';
export * from './threeDCompare';
export * from './threeDFloating';
export * from './threeDLink';
export * from './threeDMesh';
export * from './threeDPointCloud';
export * from './twoD';
export * from './twoDCompare';
export * from './twoDCurrent';
export * from './twoDEtc';
export * from './twoDFloating';
export * from './twoDGreen';
export * from './twoDLink';
export * from './twoDPanorama';
export * from './twoDTool';
export * from './video';
export * from './zone';
export * from './dailySchedule';
export * from './photoBox';

export const sendEventWhenSecondaryOpen = (t: SecondaryMenuType, mapType: '2d' | '3d') => {
  switch (t) {
    case 'points':
      return sendTwoDToolPointLinkBtn();
    case 'polylines':
      return sendTwoDToolPolylineLinkBtn();
    case 'polygons':
      return sendTwoDToolPolygonLinkBtn();
    case 'drawings':
      return sendTwoDCompareDrawingLinkBtn();
    case 'planLevel': {
      return mapType === '2d'
        ? sendTwoDComparePlanLevelLinkBtn()
        : sendThreeDComparePlanLevelLinkBtn();
    }
    case 'stratums':
      return sendTwoDCompareEarthLevelLinkBtn();
    case 'etc':
      return sendTwoDEtcLinkBtn();
    case 'bim':
      return sendThreeDCompareBimLinkBtn();
    default:
  }
};
