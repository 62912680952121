import * as actions from 'store/data/actions';

export default function mapDispatchToProps(dispatch) {
  return {
    dispatch: {
      apiError: {
        clear() {
          dispatch(actions.clearApiError());
        },
      },
    },
  };
}

export type DispatchWrapper = ReturnType<typeof mapDispatchToProps>;
