import { Typography } from '@material-ui/core';
import { ContainedButton } from 'components/common/button/ContainedButton';
import { sendTwoDEarthworkZoneEditorZoneAddBtn } from 'ga/actions/twoDEarthworkZone';
import { nls } from 'locale/language';
import React from 'react';
import EditorPageLayout from 'styleComponents/editorPageLayout';
import ListStartIcon from 'styleComponents/icon/ListStartIcon';
import styled from 'styled-components';
import theme from 'styles/mui/theme';
import { layerIcons } from '../detailsPage/sideMenu/common/layerUtils';
import useDrawingVectorEdit from './useDrawingVectorEdit';

export default function DrawingVectorEditorLeftSideBar() {
  const { bulkPolygons, selectedBulkPolygonTempId, setSelectedBulkPolygonTempId, setCreateMode } =
    useDrawingVectorEdit();

  function onClickAddButton() {
    setCreateMode(true);
    setSelectedBulkPolygonTempId(false);
    sendTwoDEarthworkZoneEditorZoneAddBtn();
  }

  return (
    <EditorPageLayout.LeftSideBar>
      <LeftSideBarTitle>
        <Typography variant="subtitle2" color="inherit">
          {nls.drawingVectorEditorLeftSideTitle()}
        </Typography>
      </LeftSideBarTitle>
      <LeftSideBarContent>
        {bulkPolygons?.map((item) => (
          <LeftSideBarItem
            key={item.tempId}
            onClick={() => setSelectedBulkPolygonTempId(item.tempId)}
            className={selectedBulkPolygonTempId === item.tempId ? 'selected' : ''}
          >
            <ListStartIcon color={item.color}>{layerIcons.polygons}</ListStartIcon>
            <Typography variant="body2" color="inherit">
              {item.name}
            </Typography>
          </LeftSideBarItem>
        ))}
      </LeftSideBarContent>
      <LeftSideBarButtonBox>
        <AddPolygonVectorButton
          onClick={onClickAddButton}
          size="large"
          fullWidth
          startIcon={layerIcons.polygons}
        >
          {nls.addPolygonVectorButtonTitle()}
        </AddPolygonVectorButton>
      </LeftSideBarButtonBox>
    </EditorPageLayout.LeftSideBar>
  );
}

const LeftSideBarTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
`;

const LeftSideBarContent = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  flex: 1;
  gap: 0.75rem;
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${theme.palette.grey[600]};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${theme.palette.grey[700]};
  }
`;

const LeftSideBarItem = styled.li`
  display: flex;
  align-items: center;
  color: ${theme.palette.primary.contrastText};
  min-height: 1.875rem;
  padding: 0 1.5rem;
  cursor: pointer;
  gap: 0.25rem;

  :hover {
    background-color: ${theme.palette.grey[700]};
  }

  &.selected {
    background-color: ${theme.palette.grey[800]};
  }
`;

const LeftSideBarButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5.5rem;
  padding: 0 1.5rem;
`;

const AddPolygonVectorButton = styled(ContainedButton)`
  height: 2.5rem;
  background-color: ${theme.palette.grey[400]};
  color: ${theme.palette.grey[800]};
  :hover {
    background-color: ${theme.palette.grey[500]};
  }
`;
