import { getTimelineLaborers } from 'common/laborerUtils';
import { useMenuValueContext } from 'components/snapshot/detailsPage/context/MenuContext';
import { formatISO } from 'date-fns';
import { useAtom, useAtomValue } from 'jotai';
import { Laborer, LaborerLocationHistory } from 'query/laborer/types';
import {
  useLaborerLocationHistoryListQuery,
  useLaborerLocationListQuery,
  useLaborerLocationTabQuery,
} from 'query/laborer/useLaborerQuery';
import { laborerKeys } from 'query/queryKeys';
import { useEffect } from 'react';
import { QueryClient, QueryKey, useQueryClient } from 'react-query';
import { LaborerBaseDate, LaborerSlider, LaborerTabValue } from 'store/data/types';
import { laborerAtom } from 'store/v3/snapshotDetail/twoD';

interface Props {
  projectId: number;
}
export function useLaborerData({ projectId }: Props) {
  const [baseDate, setBaseDate] = useAtom(laborerAtom.baseDate);
  const [slider, setSlider] = useAtom(laborerAtom.slider);
  const queryClient = useQueryClient();
  const { secondaryMenuType } = useMenuValueContext();
  const checked = useAtomValue(laborerAtom.checked);
  const expanded = useAtomValue(laborerAtom.expanded);
  const visible = secondaryMenuType === 'laborer' || Boolean(checked || expanded);
  const tabValue = useLaborerLocationTabQuery({ projectId })?.data;

  useLaborerLocationListQuery({
    projectId,
    enabled: visible && tabValue === 'LABORER_LOCATION_LIST',
  });
  useLaborerLocationHistoryListQuery({
    projectId,
    enabled: visible && tabValue === 'LABORER_LOCATION_HISTORY_LIST',
    baseDate,
    slider,
  });

  useEffect(() => {
    queryClient.removeQueries(laborerKeys.listHistory());
  }, [baseDate]);

  const laborerLocationData = getLaborerLocationData(queryClient, {
    projectId,
    tabValue,
    baseDate,
    slider,
  });

  return {
    laborerBaseDate: baseDate,
    setLaborerBaseDate: setBaseDate,
    laborerSlider: slider,
    setLaborerSlider: setSlider,
    laborerLocationData,
  };
}

const getLaborerLocationList = (queryClient: QueryClient, projectId: number) => {
  const queryKey = laborerKeys.list(projectId);
  const queryData: Laborer[] = queryClient.getQueryData(queryKey) || [];

  return {
    laborers: queryData,
    isEmpty: queryData.length === 0,
    isLoading: false,
  };
};

const getLaborerLocationHistoryList = (
  queryClient: QueryClient,
  projectId: number,
  baseDate: LaborerBaseDate,
  slider: LaborerSlider,
) => {
  const newDate = new Date(baseDate);
  const baseDateISO = formatISO(newDate);
  const baseTimeISO = formatISO(newDate.setHours(Math.floor(slider / 1000 / 60 / 60), 0, 0, 0));

  const dateQueryKey = laborerKeys.listHistoryByBaseDate(projectId, baseDateISO);
  const dateAndTimeQueryKey = laborerKeys.listHistoryByBaseDateAndTime(
    projectId,
    baseDateISO,
    baseTimeISO,
  );
  const queryData: [QueryKey, LaborerLocationHistory][] = queryClient.getQueriesData(dateQueryKey);
  const { isFetching } = queryClient.getQueryState(dateAndTimeQueryKey);

  const timeline = queryData.reduce((acc, cur) => [...acc, ...(cur[1]?.timeLines || [])], []);
  const timelineEmpty = !Boolean(queryData[0][1]?.hasData);
  const timelineData = getTimelineLaborers(timeline, slider);

  return {
    laborers: timelineData,
    isEmpty: timelineEmpty,
    isLoading: isFetching,
  };
};

export const getLaborerLocationData = (
  queryClient: QueryClient,
  {
    tabValue,
    projectId,
    baseDate,
    slider,
  }: {
    tabValue: LaborerTabValue;
    projectId: number;
    baseDate: LaborerBaseDate;
    slider: LaborerSlider;
  },
) => {
  const laborerLocationData =
    tabValue === 'LABORER_LOCATION_LIST'
      ? getLaborerLocationList(queryClient, projectId)
      : getLaborerLocationHistoryList(queryClient, projectId, baseDate, slider);
  return laborerLocationData;
};

export const setLaborerTabValue = (
  queryClient: QueryClient,
  projectId: number,
  value: LaborerTabValue,
) => {
  queryClient.setQueryData(laborerKeys.tab(projectId), value);
};
export const setLaborerViewHazardArea = (
  queryClient: QueryClient,
  projectId: number,
  value: boolean,
) => {
  queryClient.setQueryData(laborerKeys.viewHazardArea(projectId), value);
};

export const getLaborerConfigFunctions = (queryClient: QueryClient, projectId: number) => ({
  setLaborerTabValue: (value: LaborerTabValue) => setLaborerTabValue(queryClient, projectId, value),
  setLaborerViewHazardArea: (value: boolean) =>
    setLaborerViewHazardArea(queryClient, projectId, value),
});
