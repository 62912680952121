import { getJWTToken } from 'common/utils';
import { defaultClient as client } from './common';

export default {
  create(payload) {
    const { project_id, name } = payload;
    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.post(`/v3/projects/${project_id}/zones`, { name });
  },
  read(payload) {
    const { project_id, zone_id } = payload;
    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.get(`/v3/projects/${project_id}/zones/${zone_id}`);
  },
  list(payload) {
    const { project_id } = payload;
    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.get(`/v3/projects/${project_id}/zones`);
  },
  update(params) {
    const { zone_id, project_id, payload } = params as {
      zone_id: number;
      project_id: number;
      payload: {
        name: string;
      };
    };
    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.patch(`/v3/projects/${project_id}/zones/${zone_id}`, payload);
  },
  delete(payload) {
    const { project_id, zone_id } = payload;
    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.delete(`/v3/projects/${project_id}/zones/${zone_id}`);
  },
};
