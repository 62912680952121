import { atom } from 'jotai';
import { BulkPolygon } from 'query/annotation/drawingVector/types';

/** @todo dir 위치 변경 필요 */
type DrawingVectorState = {
  id: number;
  visible?: boolean;
};

export type EditingBulkPolygon = Partial<BulkPolygon> & { tempId?: number };

export const drawingVectorAtom = {
  expanded: atom<boolean>(false),
  enabled: atom<boolean>(false),
  states: atom<DrawingVectorState[]>([]),
  selectedIdForVersion: atom<number | false>(false),
};

export const drawingVectorEditingAtom = {
  bulkPolygons: atom<EditingBulkPolygon[]>([]),
  selectedBulkPolygonTempId: atom<number | false>(false),
  createMode: atom<boolean>(false),
  dirty: atom<boolean>(false),
};

export const drawingVectorVersionAtom = {
  selectedId: atom<number | false>(false),
};
