import ButtonBase, { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import React, { ElementType } from 'react';
import { LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { hexToRgba } from 'styles/colors/utils';
import theme from 'styles/mui/theme';
import { tooltipTextStyle } from 'styles/text/tooltip';

type Props = ButtonBaseProps & {
  icon: JSX.Element;
  children?: string | JSX.Element;
  component?: ElementType;
  className?: '' | 'dark-theme';
  selected?: boolean;
  disabled?: boolean;
};

type LinkPropsType = Props & LinkProps;
export default function VerticalCustomButton(props: Props | LinkPropsType) {
  const { icon, children, selected, disabled = false, ...rest } = props;
  return (
    <VerticalButton $selected={selected} $disabled={disabled} disabled={disabled} {...rest}>
      {icon}
      {children}
    </VerticalButton>
  );
}

const VerticalButton = styled(ButtonBase)<{ $selected: boolean; $disabled: boolean }>`
  && {
    ${tooltipTextStyle};
    display: flex;
    flex-direction: column;
    margin-left: 0.25rem;
    padding: 0.125rem 0.25rem;
    border-radius: 0.25rem;
    color: ${theme.palette.text.primary};
    white-space: nowrap;
    svg {
      color: ${theme.palette.text.secondary};
      path {
        fill: ${theme.palette.text.secondary};
        fill-opacity: 1;
      }
    }
    &:hover {
      background-color: ${theme.palette.action.hover};
    }
    ${({ $selected }) =>
      $selected &&
      css`
        background-color: ${theme.palette.action.selected};
      `}
    ${({ $disabled }) =>
      $disabled &&
      css`
        svg {
          color: ${theme.palette.text.disabled};
          path {
            fill: ${theme.palette.text.disabled};
            fill-opacity: 1;
          }
        }
        color: ${theme.palette.text.disabled};
      `}
    &.dark-theme {
      color: ${theme.palette.primary.contrastText};
      svg {
        color: ${theme.palette.primary.contrastText};
        path {
          fill: ${theme.palette.primary.contrastText};
        }
      }
      &.active {
        background-color: ${hexToRgba(theme.palette.primary.contrastText, 0.3)};
      }
      ${({ $selected }) =>
        $selected &&
        css`
          background-color: ${hexToRgba(theme.palette.primary.contrastText, 0.3)};
        `}
    }
    &:focus {
      outline: none;
    }
  }
`;
