import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import TextButton from 'components/common/button/TextButton';
import { nls } from 'locale/language';
import React from 'react';
import styled from 'styled-components';
import { hexToRgba } from 'styles/colors/utils';
import theme from 'styles/mui/theme';

interface Props {
  onBack: () => void;
  centerMessage?: string;
  children?: React.ReactNode;
}

export default function TopBar({ onBack, centerMessage, children }: Props) {
  return (
    <Container>
      <DefaultButton startIcon={<CloseIcon />} onClick={onBack}>
        {nls.close()}
      </DefaultButton>
      <TopBarMessage>{centerMessage || ''}</TopBarMessage>
      <RightButtonGroup>{children}</RightButtonGroup>
    </Container>
  );
}

const Container = styled.header`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  background-color: ${hexToRgba(theme.palette.grey[900], 0.9)};
  width: 100%;
  height: 4rem;
  padding-left: 0.625rem;
  padding-right: 1.5rem;
  border-bottom: 1px solid ${theme.palette.grey[800]};
  z-index: ${theme.zIndex.appBar};
`;

const TopBarMessage = styled(Typography).attrs({
  variant: 'subtitle1',
})`
  color: ${theme.palette.primary.contrastText};
`;

const DefaultButton = styled(TextButton).attrs({ size: 'large', color: 'default' })`
  color: ${theme.palette.primary.contrastText};
`;

const RightButtonGroup = styled.div`
  display: flex;
  align-items: center;
`;
