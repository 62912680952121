import { apiAxios } from 'common/api/common';
import { Panorama } from 'query/panorama/types';

export default {
  async create({ zoneId, formData }: { zoneId: number; formData: FormData }) {
    const response = await apiAxios.post<Panorama>(`/api/v2/zones/${zoneId}/panoramas`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 0,
    });
    return response.data;
  },
  async read({ zoneId, panoramaId }: { zoneId: number; panoramaId: number }) {
    const response = await apiAxios.get<Panorama>(
      `/api/v2/zones/${zoneId}/panoramas/${panoramaId}`,
    );
    return response.data;
  },
  async update({
    zoneId,
    panoramaId,
    data,
  }: {
    zoneId: number;
    panoramaId: number;
    data: Partial<Panorama>;
  }) {
    const response = await apiAxios.patch(`/api/v2/zones/${zoneId}/panoramas/${panoramaId}`, data);
    return response.data;
  },
  async delete({ zoneId, panoramaId }: { zoneId: number; panoramaId: number }) {
    const response = await apiAxios.delete(`/api/v2/zones/${zoneId}/panoramas/${panoramaId}`);
    return response.data;
  },
  async list({ zoneId }: { zoneId: number }) {
    const response = await apiAxios.get<{ results: Panorama[] }>(
      `/api/v2/zones/${zoneId}/panoramas`,
    );
    return response.data?.results;
  },

  async make({ zoneId, stitchResourceIds }: { zoneId: number; stitchResourceIds: number[] }) {
    const response = await apiAxios.post(`/api/v2/zones/${zoneId}/panoramas`, {
      type: 'STITCH',
      stitch_sources: stitchResourceIds,
    });
    return response.data;
  },
};
