import { isDev } from 'common/utils/env';
import { useSetAtom } from 'jotai';
import { globalDialogAtom } from 'store/v3/dialog';
import { DialogProps } from 'styleComponents/dialog/types';

type ShowDialogParams = {
  title?: DialogProps['title'];
  content?: DialogProps['content'];
  primaryButtonProps?: { title?: string; onClick?: () => void; color?: 'primary' | 'secondary' };
  secondaryButtonProps?: { title?: string; onClick?: () => void; color?: 'primary' | 'secondary' };
  onClose?: DialogProps['onClose'];
  progressBar?: DialogProps['progressBar'];
  children?: DialogProps['children'];
};

export default function useGlobalDialog() {
  const setDialog = useSetAtom(globalDialogAtom);

  function showAlert({ content, primaryButtonProps, progressBar }: ShowDialogParams) {
    printStackIfDevMode('showAlert');
    setDialog({
      type: 'alert',
      open: true,
      content,
      progressBar,
      primaryButtonProps: {
        title: primaryButtonProps?.title,
        color: primaryButtonProps?.color,
        onClick: () => {
          primaryButtonProps?.onClick?.();
          setDialog({ open: false });
        },
      },
    });
  }

  function showConfirm({
    title,
    content,
    primaryButtonProps,
    secondaryButtonProps,
    children,
  }: ShowDialogParams) {
    printStackIfDevMode('showConfirm');
    setDialog({
      type: 'confirm',
      open: true,
      title,
      content,
      primaryButtonProps: {
        title: primaryButtonProps?.title,
        color: primaryButtonProps?.color,
        onClick: () => {
          primaryButtonProps?.onClick?.();
          closeDialog();
        },
      },
      secondaryButtonProps: {
        title: secondaryButtonProps?.title,
        color: secondaryButtonProps?.color,
        onClick: () => {
          secondaryButtonProps?.onClick?.();
          closeDialog();
        },
      },
      children,
    });
  }

  function showModal({
    title,
    content,
    primaryButtonProps,
    secondaryButtonProps,
    onClose,
  }: ShowDialogParams) {
    printStackIfDevMode('showModal');
    setDialog({
      type: 'modal',
      open: true,
      title,
      content,
      primaryButtonProps: {
        title: primaryButtonProps?.title,
        color: primaryButtonProps?.color,
        onClick: () => {
          primaryButtonProps?.onClick?.();
          closeDialog();
        },
      },
      secondaryButtonProps: {
        title: secondaryButtonProps?.title,
        color: secondaryButtonProps?.color,
        onClick: () => {
          secondaryButtonProps?.onClick?.();
          closeDialog();
        },
      },
      onClose: () => {
        onClose?.();
        closeDialog();
      },
    });
  }

  function closeDialog() {
    setDialog({ open: false });
  }

  // Only works in Chrome
  function printStackIfDevMode(from: string) {
    const isChrome = /Chrome/.test(navigator.userAgent);
    if (isDev && isChrome) {
      console.warn(
        from,
        '\n',
        new Error().stack?.replace('Error', '').split('\n').slice(2).join('\n'),
      );
    }
  }

  return {
    showAlert,
    showConfirm,
    showModal,
    closeDialog,
  };
}
