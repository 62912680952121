import { Atom, PrimitiveAtom, atom } from 'jotai';

/** @deprecated */
export type DrawingAtom = {
  expanded: {
    root: PrimitiveAtom<boolean>;
    folders: PrimitiveAtom<{ [x: number]: boolean }>;
  };
  checked: {
    items: PrimitiveAtom<{ [x: number]: boolean }>;
  };
  visible: { menu: Atom<boolean>; map: Atom<boolean> };
};

/** @deprecated */
export const drawing: DrawingAtom = {
  expanded: {
    root: atom(false),
    folders: atom<{ [x: number]: boolean }>({}),
  },
  checked: {
    items: atom<{ [x: number]: boolean }>({}),
  },
  visible: {
    map: atom((get) => {
      const items = get(drawing.checked.items);
      return Object.values(items).some((v) => v);
    }),
    menu: atom((get) => {
      const visibleMap = get(drawing.visible.map);
      return visibleMap || get(drawing.expanded.root);
    }),
  },
};

/** New version */
type FolderState = {
  id: number;
  expanded?: boolean;
};

export type DrawingState = {
  id: number;
  folderId?: number;
  /* visible이 필요한 모든 곳에서 사용 */
  visible?: boolean;
  /* map에서만 보여줘야하는 경우 사용 */
  visibleOnMap?: boolean;
  opacity?: number;
};

const drawingEnabledAtom = atom<boolean>(false);
const drawingAccordionExpandedAtom = atom<boolean>(false);
const drawingStatesAtom = atom<DrawingState[]>([]);
const drawingFolderStatesAtom = atom<FolderState[]>([]);

export const drawingAtom = {
  enabled: drawingEnabledAtom,
  expanded: drawingAccordionExpandedAtom,
  states: drawingStatesAtom,
  folderStates: drawingFolderStatesAtom,
};
