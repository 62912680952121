import Grow from '@material-ui/core/Grow';
import { makeStyles } from '@material-ui/styles';
import { MOBILE_MAX_WIDTH } from 'common/policies/screen';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { NoticeResult } from 'query/notice/types';
import { useNoticeQuery } from 'query/notice/useNoticeQuery';
import React, { ComponentType, useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'styles/mui/theme';
import AlertSnackbar from './AlertSnackbar';
import NoticeSnackbar from './Snackbar';

const useStyles = makeStyles(() => ({
  containerRoot: {
    zIndex: 999999999, // 채널톡보다 위에 띄우기
  },
}));

export default function NoticeSnackbarProvider() {
  const notices = useNoticeQuery().data || [];
  const [alertOpen, setAlertOpen] = useState(false);
  const classes = useStyles();

  const handleAlertClose = (e: any, reason: any) => {
    if (reason === 'clickaway') return;
    setAlertOpen(false);
  };
  const handleAlertShow = () => {
    setAlertOpen(true);
  };

  // https://notistack.com/getting-started
  // SnackbarProvider 로 enqueueSnackbar, closeSnackbar 등을 호출할 컴포넌트를 감싸줘야 합니다.
  // 그러므로 NoticeSnackbars 컴포넌트를 children 으로 넘겨줍니다.
  return (
    <StyledSnackbarProvider
      autoHideDuration={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      TransitionComponent={Grow as ComponentType}
      classes={classes}
    >
      <AlertSnackbar open={alertOpen} onClose={handleAlertClose} />
      <NoticeSnackbars notices={notices} showAlert={handleAlertShow} />
    </StyledSnackbarProvider>
  );
}

const NoticeSnackbars = ({ notices, showAlert }) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const filteredNotices = notices.filter(
      (notice: NoticeResult) => !localStorage.getItem(`noticeDisable-${notice.id}`),
    );

    filteredNotices.map((notice: NoticeResult) => {
      enqueueSnackbar(<NoticeSnackbar notice={notice} showAlert={showAlert} />, { key: notice.id });
    });
  }, [notices]);

  // SnackbarProvider 에 children 으로 넘긴 본 컴포넌트는 아무것도 렌더링하지 않으며
  // useEffect 에서 enqueueSnackbar 을 호출하여 snackbar 를 띄웁니다.
  return null;
};

const StyledSnackbarProvider = styled(SnackbarProvider)`
  overflow: hidden;
  width: 19.5rem;
  padding: 0;
  background: none;
  border-radius: 0.5rem;
  box-shadow: ${theme.shadows[6]};
  margin-right: 4.75rem;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    width: 100%;
  }

  > div {
    display: block;
    width: 100%;
    padding: 0;
  }
`;
