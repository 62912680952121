import { Action } from 'store/types';
import constants from './constants';
import { AerialImage, AerialImageData } from './types';

const actions = {
  createAerialImages(snapshotId: number, files: AerialImage[]) {
    return {
      type: constants.CREATE_AERIAL_IMAGES,
      payload: { snapshotId, files },
    };
  },
  cancelCreateAerialImages() {
    return {
      type: constants.CANCEL_CREATE_AERIAL_IMAGES,
      payload: {},
    };
  },
  readAerialImage(snapshotId: number, aerialImageId: number, failCb: Function) {
    return {
      type: constants.READ_AERIAL_IMAGE,
      payload: { snapshotId, aerialImageId, failCb },
    };
  },
  updateAerialImage(snapshotId: number, aerialImageId: number, payload) {
    return {
      type: constants.UPDATE_AERIAL_IMAGE,
      payload: { snapshotId, aerialImageId, ...payload },
    };
  },
  deleteAerialImage(snapshotId: number, aerialImage: AerialImage) {
    return {
      type: constants.DELETE_AERIAL_IMAGE,
      payload: { snapshotId, aerialImage },
    };
  },
  listAerialImages(snapshotId: number, refetch = false) {
    return {
      type: constants.LIST_AERIAL_IMAGES,
      payload: { snapshotId, refetch },
    };
  },
  setAerialImage(aerialImageData: AerialImageData) {
    return {
      type: constants.SET_AERIAL_IMAGE,
      payload: aerialImageData,
    };
  },
  setAerialImages(aerialImageData: AerialImageData) {
    return {
      type: constants.SET_AERIAL_IMAGES,
      payload: aerialImageData,
    };
  },
  setUploadingAerialImages(files: AerialImage[]) {
    return {
      type: constants.SET_UPLOADING_AERIAL_IMAGES,
      payload: files,
    };
  },
  setUploadingAerialImage(uploadingImage: AerialImage) {
    return {
      type: constants.SET_UPLOADING_AERIAL_IMAGE,
      payload: uploadingImage,
    };
  },
  setDeletedAerialImage(deletedImage: AerialImage) {
    return {
      type: constants.SET_DELETED_AERIAL_IMAGE,
      payload: deletedImage,
    };
  },
  setState(status: AerialImageData['status']) {
    return {
      type: constants.SET_STATUS,
      payload: status,
    };
  },
};

// 모든 속성이 Action을 반환하는 함수임을 보장하되,
// export되는 객체에서 타입 추론이 정상적으로 동작할 수 있도록 보장하는 코드
actions as { [key: string]: (...args: any) => Action };

export default actions;

export type ActionType<T extends keyof typeof actions> = ReturnType<typeof actions[T]>;
