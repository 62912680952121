import { Action } from 'store/types';
import * as constants from '../constants';

/* Set state */

export function set404Pages(): Action {
  return {
    type: constants.SET_404_PAGE,
    payload: {},
  };
}
export function setApiError({ status, messageCode, config }): Action {
  return {
    type: constants.SET_API_ERROR,
    payload: { status, messageCode, config },
  };
}
export function clearApiError(): Action {
  return {
    type: constants.CLEAR_API_ERROR,
    payload: '',
  };
}
