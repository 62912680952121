import aerialImage from 'store/v2/aerialImage/api';
import drawing from 'store/v2/drawing/api';
import snapshot from 'store/v2/snapshot/api';
import auth from './auth';
import file from './file';
import zone from './zone';

export default function create() {
  return {
    auth,
    snapshot,
    aerialImage,
    zone,
    file,
    drawing,
  };
}

export type Api = ReturnType<typeof create>;
