import SvgIcon from '@material-ui/core/SvgIcon';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SettingsIcon from '@material-ui/icons/Settings';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import InventoryIcon from '@mui/icons-material/Inventory';
import { sideMenuWidth } from 'common/policies/sizes';
import { getNewChip } from 'components/common/Chip';
import GA from 'ga';
import {
  PROJECT_FILE_ARCHIVE_MENU,
  PROJECT_VEHICLE_HISTORY_MENU,
  PROJECT_WORKER_HISTORY_MENU,
} from 'ga/actions';
import { PROJECT } from 'ga/category';
import { nls } from 'locale/language';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import routes from 'routes';
import { Project } from 'store/data/types';
import styled from 'styled-components';
import theme from 'styles/mui/theme';

export type TabType =
  | 'PROJECT_SETTINGS'
  | 'PROJECT_USER_SETTINGS'
  | 'FILE_ARCHIVE'
  | 'LABORER_HISTORY'
  | 'VEHICLE_HISTORY';

interface Props {
  project: Project;
  selectedTab: TabType;
  className?: string;
}

export default function SideMenu({ project, selectedTab, className }: Props) {
  const history = useHistory();
  const match = useRouteMatch();
  const [tab, setTab] = useState<TabType>(selectedTab);

  useEffect(() => {
    switch (match.path) {
      case routes.project.info.path:
      case routes.project.edit.path:
        setTab('PROJECT_SETTINGS');
        break;
      case routes.project.member.info.path:
        setTab('PROJECT_USER_SETTINGS');
        break;
      case routes.project.archive.path:
        setTab('FILE_ARCHIVE');
        break;
      case routes.project.laborerHistory.path:
        setTab('LABORER_HISTORY');
        break;
      case routes.project.vehicleHistory.path:
        setTab('VEHICLE_HISTORY');
        break;
      default:
        setTab('PROJECT_USER_SETTINGS');
    }
  }, [match]);

  function navigateIfNeeded(dest: string) {
    // 현재 위치와 목표지가 같다면 이동할 필요 없음
    if (match.url === dest) {
      return;
    }
    history.push(dest);
  }

  function goToProjectInfo() {
    navigateIfNeeded(routes.project.info.of({ projectId: project.id }));
  }
  function goToParticipantList() {
    navigateIfNeeded(routes.project.member.info.of({ projectId: project.id }));
  }
  function goToFileArchive() {
    navigateIfNeeded(routes.project.archive.of({ projectId: project.id }));
    GA.event(PROJECT, PROJECT_FILE_ARCHIVE_MENU, 'setting');
  }
  function goToLaborerHistory() {
    navigateIfNeeded(routes.project.laborerHistory.of({ projectId: project.id }));
    GA.event(PROJECT, PROJECT_WORKER_HISTORY_MENU, 'setting');
  }
  function goToVehicleHistory() {
    navigateIfNeeded(routes.project.vehicleHistory.of({ projectId: project.id }));
    GA.event(PROJECT, PROJECT_VEHICLE_HISTORY_MENU, 'setting');
  }
  const hasPermissionToSeeParticipants = project?.permissionInfo?.userGet;

  return (
    <Layout className={className}>
      <StyledTabs value={tab} onChange={(_, x) => setTab(x)}>
        <StyledTab
          value="PROJECT_USER_SETTINGS"
          icon={<MembersIcon />}
          label={nls.participantsInfo()}
          onClick={goToParticipantList}
          $disabled={!hasPermissionToSeeParticipants}
        />
        <StyledTab
          value="PROJECT_SETTINGS"
          icon={<ProjectIcon />}
          label={nls.projectInfo()}
          onClick={goToProjectInfo}
        />
        <StyledTab
          value="FILE_ARCHIVE"
          icon={<ArchiveSvgIcon component={InventoryIcon} />}
          label={nls.fileStorage()}
          onClick={goToFileArchive}
        />
        <StyledTab
          value="LABORER_HISTORY"
          icon={<LaborerHistoryIcon />}
          label={labelWithNew(nls.laborerHistory())}
          onClick={goToLaborerHistory}
        />
        <StyledTab
          value="VEHICLE_HISTORY"
          icon={<VehicleHistoryIcon />}
          label={labelWithNew(nls.vehicleHistory())}
          onClick={goToVehicleHistory}
        />
      </StyledTabs>
    </Layout>
  );
}

const labelWithNew = (label: string) => {
  return (
    <div>
      {label}
      <span className="new" />
    </div>
  );
};

const StyledTabs = styled(Tabs).attrs({
  textColor: 'primary',
  orientation: 'vertical',
  variant: 'fullWidth',
  indicatorColor: 'primary',
})`
  .MuiTabs-indicator {
    transition: none;
  }
`;
const Layout = styled.div`
  background-color: ${theme.palette.background.paper};
  height: 100%;
  width: ${sideMenuWidth};
  border-right: 1px solid ${theme.palette.divider};
`;

const StyledTab = styled(Tab).attrs({ variant: 'button' })<{ $disabled?: boolean }>`
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  min-height: 3rem;
  visibility: visible;
  &:first-child {
    margin-top: 2.1rem;
  }

  &:not(.Mui-selected) svg {
    color: ${theme.palette.text.secondary};
  }

  &:focus {
    outline: none;
  }

  .MuiTab-wrapper {
    flex-direction: row;
    align-items: center;
    justify-content: start;

    *:first-child {
      margin-bottom: 0;
    }
  }
  ${getNewChip()}
  ${({ $disabled }) => $disabled && 'display: none;'}
`;

const ProjectIcon = styled(SettingsIcon)`
  color: ${theme.palette.primary.main};
  margin-right: 0.6rem;
`;

const ArchiveSvgIcon = styled(SvgIcon)<{ component: React.ElementType }>`
  color: ${theme.palette.primary.main};
  margin-right: 0.6rem;
`;

const MembersIcon = styled(SupervisorAccountIcon)`
  color: ${theme.palette.primary.main};
  margin-right: 0.6rem;
`;

const LaborerHistoryIcon = styled(WatchLaterIcon)`
  color: ${theme.palette.primary.main};
  margin-right: 0.6rem;
`;

const VehicleHistoryIcon = styled(LocalShippingIcon)`
  color: ${theme.palette.primary.main};
  margin-right: 0.6rem;
`;
