import { isJapan } from 'common/customize';
import { BING_MAP_OPTIONS } from 'common/policies/map';
import { useMatchParams } from 'components/common/hooks';
import { useProjectQuery, useVworldMapKeyQuery } from 'query';
import React from 'react';
import { BingLayer } from 'react-leaflet-bing-v2';
import VWorldSatellite from './VWorldSatellite';

export default function SatelliteLayer(props) {
  const { projectId } = useMatchParams();
  const { data: project } = useProjectQuery(projectId);
  const { mapProvider } = project || {};
  const { data: vWorldMapKey } = useVworldMapKeyQuery();

  if (vWorldMapKey && mapProvider === 'VWORLD') {
    return <VWorldSatellite mapKey={vWorldMapKey} {...props} />;
  }

  if (mapProvider === 'BING') {
    return <BingLayer {...BING_MAP_OPTIONS} {...props} />;
  }

  //return <ReactLeafletGoogleLayer {...GOOGLE_MAP_OPTIONS} />;
  /** @fixme 기본 지도가 구글이지만, 구글 지도 줌레벨 16이상 로드가 안되는 이슈로 임시 변경. https://meissa-ai.slack.com/archives/C01BYA818G1/p1721088672338129  */
  if (isJapan()) {
    return <BingLayer {...BING_MAP_OPTIONS} {...props} />;
  } else {
    return <VWorldSatellite mapKey={vWorldMapKey} {...props} />;
  }
}
