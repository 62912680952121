import { atom } from 'jotai';
import { LatLngLiteral } from 'leaflet';
import { LaborerBaseDate, LaborerSlider } from 'store/data/types';

export const laborerAtom = {
  expanded: atom(false),
  checked: atom(false),
  baseDate: atom<LaborerBaseDate>(new Date(new Date().setHours(0, 0, 0, 0))),
  slider: atom<LaborerSlider>(0),
  focused: atom<{ latLng: LatLngLiteral; id?: number; zoom: number } | false>(false),
};

export const laborerBoundingBox = {
  checked: atom<boolean>(false),
};
