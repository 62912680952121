import { SecondaryMenuType } from 'components/snapshot/detailsPage/types';
import { BaseAnnotation } from 'query/annotation/types';
import { ResourceType } from 'query/resource/types';

export function getCheckboxLabel(checked: boolean): string {
  return checked ? 'On' : 'Off';
}

export function getConnectionLabel(bool: boolean): string {
  return bool ? 'Connect' : 'Disconnect';
}

export function getArchiveLabel(
  type:
    | Exclude<SecondaryMenuType, 'laborer'>
    | BaseAnnotation['annotationType']
    | ResourceType
    | 'SETTING',
) {
  switch (type) {
    case 'points':
    case 'POINT':
      return 'Point';
    case 'polylines':
    case 'POLYLINE':
      return 'Polyline';
    case 'polygons':
    case 'POLYGON':
      return 'Polygon';
    case 'chains':
    case 'ANNOTATION_CSV':
      return 'Chain';
    case 'planLevel':
    case 'PLAN_LEVEL':
      return 'Plan Level';
    case 'stratums':
    case 'STRATUM':
      return 'Stratum';
    case 'bim':
    case 'BIM':
      return 'bim';
    case 'DRAWING_RASTER':
    case 'DRAWING_VECTOR':
    case 'DRAWING_POSITIONING':
    case 'drawings':
      return 'drawing';
    default:
      return 'setting';
  }
}
