import { AxiosError } from 'axios';
import { DEFAULT_QUERY_STALE_TIME } from 'common/policies/request';
import { fileArchiveKeys } from 'query/queryKeys';
import { useQuery, UseQueryOptions } from 'react-query';
import api from './api';
import { ArchiveFileResponse, FileArchiveProps } from './types';

export function useFileArchiveQuery(
  { projectId, page, size, search }: FileArchiveProps,
  options?: UseQueryOptions<ArchiveFileResponse, AxiosError<Error>>,
) {
  const { enabled = true, ...rest } = options || {};
  return useQuery(
    fileArchiveKeys.list(projectId, page, size, search),
    () => api.list({ projectId, page, size, search }),
    {
      enabled,
      staleTime: DEFAULT_QUERY_STALE_TIME,
      keepPreviousData: true,
      ...rest,
    },
  );
}
