import { getPositionFromExif } from 'common/utils';
import { LoadingStatus } from 'store/data/types';
import { ActionType } from './actions';
import constants from './constants';
import { AerialImage, AerialImageData } from './types';

export default function reducer(
  state: AerialImageData = { data: [] },
  action:
    | ActionType<'setState'>
    | ActionType<'setAerialImage'>
    | ActionType<'setAerialImages'>
    | ActionType<'setUploadingAerialImage'>
    | ActionType<'setUploadingAerialImages'>
    | ActionType<'setDeletedAerialImage'>,
) {
  switch (action.type) {
    case constants.SET_STATUS: {
      const { payload } = action as ActionType<'setState'>;
      return {
        ...state,
        status: { ...state?.status, ...payload },
      };
    }
    case constants.SET_AERIAL_IMAGES: {
      const { payload } = action as ActionType<'setAerialImages'>;
      if (!payload.refetch) {
        return {
          ...state,
          ...payload,
          data: [
            ...(payload.data || [])?.map((x) => {
              const position = !x?.position && x?.gpsInfo && getPositionFromExif(x.gpsInfo);

              return {
                ...(position ? { ...x, position: getPositionFromExif(x.gpsInfo) } : x),
                status: 'SUCCESS' as const,
              } as AerialImage;
            }),
          ],
        };
      }

      // refetch가 true일 때 빈배열로 초기화 하지 않음
      return {
        ...state,
        ...payload,
        status: { ...state?.status, list: payload.status },
      } as AerialImageData;
    }
    case constants.SET_AERIAL_IMAGE: {
      // 단순 데이터 조회용
      const { payload } = action as ActionType<'setAerialImage'>;
      const updated =
        state?.data && state.data.length > 0
          ? state.data.map((x) => (x.id === payload?.item?.id ? payload?.item : x))
          : [payload?.item];
      return { ...state, ...payload, data: updated, item: payload?.item };
    }
    case constants.SET_UPLOADING_AERIAL_IMAGES: {
      const { payload } = action as ActionType<'setUploadingAerialImages'>;
      return { ...state, data: [...state?.data, ...(payload || [])] };
    }
    case constants.SET_UPLOADING_AERIAL_IMAGE: {
      const { payload } = action as ActionType<'setUploadingAerialImage'>;

      const totalCount = getTotalCount(state);
      const settledCount = getSettledCount(payload, state);
      const createStatus = getCreateStatus(settledCount, totalCount);

      return {
        ...state,
        data: state?.data.map((x) => (x?.tempId === payload.tempId ? { ...x, ...payload } : x)),
        status: {
          ...state?.status,
          create: createStatus,
        },
      };
    }
    case constants.SET_DELETED_AERIAL_IMAGE: {
      const { payload } = action as ActionType<'setDeletedAerialImage'>;
      return {
        ...state,
        data: state?.data?.filter((x) => x.id !== payload.id || x.tempId !== payload?.tempId),
      };
    }
    default:
      return state;
  }
}

function getSettledCount(payload: AerialImage, state: AerialImageData) {
  return (
    (!isPartial(state) && 0) ||
    (isTargetToUpdate(payload) && (state?.status?.create?.[1] as number) + 1) ||
    (state?.status?.create?.[1] as number)
  );
}

function getTotalCount(state: AerialImageData) {
  return isPartial(state) ? state?.status?.create?.[2] : 0;
}

function getCreateStatus(settledCount: number, totalCount: string | number) {
  return (
    settledCount === totalCount ? 'SUCCESS' : ['PARTIAL', settledCount, totalCount]
  ) as LoadingStatus;
}

function isTargetToUpdate(payload: AerialImage) {
  return payload?.status === 'ERROR' || payload?.status === 'SUCCESS';
}
function isPartial(state: AerialImageData) {
  return state?.status?.create?.[0] === 'PARTIAL';
}
