import aerialImageActions from './v2/aerialImage/actions';
import aerialImageConstants from './v2/aerialImage/constants';
import aerialImageReducer from './v2/aerialImage/reducer';
import aerialImageSagas from './v2/aerialImage/sagas';
import apiErrorReducer from './v2/apiError/reducer';
import drawingActions from './v2/drawing/actions';
import drawingConstants from './v2/drawing/constants';
import drawingReducer from './v2/drawing/reducer';
import drawingSagas from './v2/drawing/sagas';
import ResourceReducer, {
  actions as ResourceActions,
  constants as ResourceConstants,
} from './v2/resource';
import snapshotActions from './v2/snapshot/actions';
import snapshotConstants from './v2/snapshot/constants';
import snapshotReducer from './v2/snapshot/reducer';

export default {
  apiError: {
    reducer: apiErrorReducer,
    sagas: undefined,
  },
  snapshot: {
    actions: snapshotActions,
    reducer: snapshotReducer,
    constants: snapshotConstants,
    sagas: undefined,
  },
  drawing: {
    actions: drawingActions,
    reducer: drawingReducer,
    constants: drawingConstants,
    sagas: drawingSagas,
  },
  aerialImage: {
    actions: aerialImageActions,
    reducer: aerialImageReducer,
    constants: aerialImageConstants,
    sagas: aerialImageSagas,
  },
  resource: {
    actions: ResourceActions,
    reducer: ResourceReducer,
    constants: ResourceConstants,
    sagas: undefined,
  },
};
