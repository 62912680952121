import { PropTypes } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import ButtonMUI, { ButtonProps } from '@material-ui/core/Button';
import React from 'react';
import { LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { hexToRgba } from 'styles/colors/utils';
import theme from 'styles/mui/theme';
import { defaultButtonStyle } from './style';

const DEFAULT_OPACITY = 0.08;
const HOVER_OPACITY = 0.22;
const ACTIVE_OPACITY = 0.35;
const LOADING_OPACITY = 0.4;

export default function TextButton(props: ButtonProps) {
  const { onClick, children, ...restProps } = props;

  return (
    <TextButtonEx onClick={onClick} variant="text" {...restProps}>
      {children}
    </TextButtonEx>
  );
}

export const getDialogButton = (
  onClick,
  btnTxt,
  color: PropTypes.Color = 'primary',
  disabled = false,
) => {
  return (
    <TextButtonEx
      onClick={onClick}
      key={btnTxt}
      color={color}
      disabled={disabled}
      variant="text"
      size="medium"
    >
      {btnTxt}
    </TextButtonEx>
  );
};

const TextButtonEx = styled(ButtonMUI)`
  ${defaultButtonStyle};
  min-width: auto;
`;

/**
 * 기본일 때도 배경색 존재하는 TextButton
 * @link https://www.figma.com/file/PP9uWWFob8FJNr7H21Hkbx/Platform-3.0-Redesign-Web?node-id=4576%3A2200
 */
type TextButtonWithBackgroundType = ButtonProps & { $loading?: boolean };
type LinkPropsType = TextButtonWithBackgroundType & LinkProps;

const getCircularProgressSize = (size: string): string => {
  switch (size) {
    case 'large':
      return '1.5rem';
    case 'small':
      return '1.125rem';
    default:
      return '1.25rem';
  }
};

export const TextButtonWithBackground = styled(ButtonMUI).attrs<
  TextButtonWithBackgroundType | LinkPropsType
>(({ $loading, size, startIcon, disabled }) => ({
  variant: 'text',
  startIcon: $loading ? <CircularProgress size={getCircularProgressSize(size)} /> : startIcon,
  disabled: disabled || $loading,
}))<TextButtonWithBackgroundType | LinkPropsType>`
  ${defaultButtonStyle};
  ${({ color }) =>
    color === 'primary' || color === 'secondary'
      ? css`
          && {
            color: ${theme.palette[color].main};
            background-color: ${hexToRgba(theme.palette[color].main, DEFAULT_OPACITY)};
            &:hover {
              background-color: ${hexToRgba(theme.palette[color].main, HOVER_OPACITY)};
            }
            &:active {
              background-color: ${hexToRgba(theme.palette[color].main, ACTIVE_OPACITY)};
            }
          }
        `
      : css`
          && {
            background-color: ${hexToRgba(theme.palette.text.primary, DEFAULT_OPACITY)};
            &:hover {
              background-color: ${hexToRgba(theme.palette.text.primary, HOVER_OPACITY)};
            }
            &:active {
              background-color: ${hexToRgba(theme.palette.text.primary, ACTIVE_OPACITY)};
            }
          }
        `}

  ${({ $loading }) =>
    $loading
      ? css`
          &&.Mui-disabled {
            opacity: ${LOADING_OPACITY};
          }
        `
      : css`
          &&.Mui-disabled {
            background-color: ${theme.palette.action.disabledBackground};
            color: ${theme.palette.text.disabled};
          }
        `}
      
  .MuiButton-iconSizeSmall .MuiCircularProgress-root {
    width: 1.125rem !important;
    height: 1.125rem !important;
  }
  .MuiButton-iconSizeLarge .MuiCircularProgress-root {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
`;
