const createConstant = (id) => `v2/aerialImages/${id}`;

export default {
  CREATE_AERIAL_IMAGES: createConstant('createAerialImages'),
  READ_AERIAL_IMAGE: createConstant('readAerialImage'),
  UPDATE_AERIAL_IMAGE: createConstant('updateAerialImage'),
  DELETE_AERIAL_IMAGE: createConstant('deleteAerialImage'),
  LIST_AERIAL_IMAGES: createConstant('listAerialImages'),
  CANCEL_CREATE_AERIAL_IMAGES: createConstant('cancelCreateAerialImages'),
  SET_AERIAL_IMAGES: createConstant('setAerialImages'),
  SET_AERIAL_IMAGE: createConstant('setAerialImage'),
  SET_UPLOADING_AERIAL_IMAGES: createConstant('setUploadingAerialImages'),
  SET_UPLOADING_AERIAL_IMAGE: createConstant('setUploadingAerialImage'),
  SET_DELETED_AERIAL_IMAGE: createConstant('setDeletedAerialImage'),
  SET_STATUS: createConstant('setStatus'),
};
