import React, { forwardRef, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from 'styles/mui/theme';
import { errorTextLeftMargin } from '../../../common/policies/sizes';
import {
  getInputComponentBorderColor,
  getInputComponentBorderWidth,
  InputComponentProps,
} from '../style/Styles';
import { textPrimary } from '../style/TextStyles';

interface Props extends React.HTMLAttributes<HTMLInputElement> {
  error?: string;
  widthType?: string;
  prefix?: string;
  className?: string;
  disabled?: boolean;
}

const CustomTextField = forwardRef<HTMLInputElement, any>((props: Props, ref) => {
  const { error = '', widthType = '', prefix, className = '', disabled = false, ...rest } = props;
  const divClassName = `${error ? 'invalid' : ''} ${className}`;

  const [focused, setFocused] = useState(false);
  const inputRef = useRef(null);

  return (
    <InputWrapper
      className={divClassName}
      ref={ref}
      $error={!!error}
      $active={focused}
      $disabled={disabled}
      onClick={() => inputRef?.current?.focus()}
    >
      <div className={widthType}>
        <span>{prefix}</span>
        <input
          {...rest}
          ref={inputRef}
          disabled={disabled}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </div>
      {!!error && <p className="helperText">{error}</p>}
    </InputWrapper>
  );
});
CustomTextField.displayName = 'CustomTextField';
export default CustomTextField;

type InputWrapperProps = InputComponentProps & { $disabled: boolean };
export const InputWrapper = styled.div<InputWrapperProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;

  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'cursor')};

  input:focus::placeholder {
    color: transparent;
  }

  div {
    display: flex;
    align-items: center;
    background: ${theme.palette.background.paper};
    border-style: solid;
    ${getInputComponentBorderColor};
    ${getInputComponentBorderWidth};
    border-radius: 0.25rem;
    padding: 0.875rem 0.75rem;
    ${textPrimary};
    flex: 1;
    min-width: 15rem;
    min-height: 3.5rem;

    &.half {
      max-width: 15rem;
    }
  }

  input {
    border: none;
    flex: 1;
    padding: 0;

    &::placeholder {
      color: ${theme.palette.text.secondary};
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      background-color: transparent;
      color: ${theme.palette.text.disabled};
    }
  }

  &.invalid {
    input {
      border-color: ${theme.palette.error.main};
    }
  }

  p.helperText {
    color: ${theme.palette.error.main};
    font-size: 0.75rem;
    line-height: 1.25rem;
    position: absolute;
    left: ${errorTextLeftMargin}; // 14px
    bottom: -1.25rem;
  }

  span {
    ${({ $disabled }) => $disabled && `color: ${theme.palette.text.disabled}`};
    white-space: pre;
  }
`;
