import { IssueUrlType } from 'query/issue/types';
import { useParams } from 'react-router-dom';

export type MatchParams<T> = {
  annotationId?: T;
  snapshotId?: T;
  projectId?: T;
  zoneId?: T;
  drawingId?: T;
  cctvId?: T;
  hazardAreaId?: T;
  issueId?: T | 'new';
  mapType?: '2d' | '3d';
  resourceId?: T;
  issueType: IssueUrlType;
  buildingId?: T;
  indoorVideoId?: T;
  greenId?: T | 'new';
  scheduleDate?: string;
  albumId?: T;
};

export function useMatchParams(): MatchParams<number | undefined> {
  const params = useParams<MatchParams<string>>();

  function getId(key: string): number | undefined {
    const value = params[key];
    return isFinite(value) ? parseInt(value, 10) : undefined;
  }

  const annotationId = getId('annotationId');
  const snapshotId = getId('snapshotId');
  const projectId = getId('projectId');
  const zoneId = getId('zoneId');
  const drawingId = getId('drawingId');
  const hazardAreaId = getId('hazardAreaId');
  const issueId = params?.issueId === 'new' ? 'new' : getId('issueId');
  const mapType = params?.mapType;
  const resourceId = getId('resourceId');
  const issueType = params?.issueType;
  const buildingId = getId('buildingId');
  const indoorVideoId = getId('indoorVideoId');
  const greenId = params?.greenId === 'new' ? 'new' : getId('greenId');
  const cctvId = getId('cctvId');
  const scheduleDate = params?.scheduleDate;
  const albumId = getId('albumId');

  return {
    projectId,
    snapshotId,
    zoneId,
    annotationId,
    drawingId,
    hazardAreaId,
    issueId,
    mapType,
    resourceId,
    issueType,
    buildingId,
    indoorVideoId,
    greenId,
    cctvId,
    scheduleDate,
    albumId,
  };
}
