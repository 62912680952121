import createTypography from '@material-ui/core/styles/createTypography';
import { DEFAULT_FONT_FAMILY } from 'components/common/style/TextStyles';
import palette from 'styles/mui/palette';

const typography = createTypography(palette, {
  fontFamily: DEFAULT_FONT_FAMILY,
  h1: {
    fontSize: '6rem',
    lineHeight: 1.2,
    color: palette.text.primary,
    fontWeight: 200,
  },
  h2: {
    fontSize: '3.75rem',
    lineHeight: 1.2,
    color: palette.text.primary,
    fontWeight: 400,
  },
  h3: {
    fontSize: '3rem',
    lineHeight: 1.3,
    color: palette.text.primary,
    fontWeight: 400,
  },
  h4: {
    fontSize: '2.125rem',
    lineHeight: 1.4,
    color: palette.text.primary,
    fontWeight: 600,
  },
  h5: {
    fontSize: '1.5rem',
    lineHeight: 1.4,
    color: palette.text.primary,
    fontWeight: 600,
  },
  h6: {
    fontSize: '1.25rem',
    lineHeight: 1.6,
    color: palette.text.primary,
    fontWeight: 600,
  },
  subtitle1: {
    fontSize: '1rem',
    color: palette.text.primary,
    lineHeight: 1.75,
    fontWeight: 600,
  },
  subtitle2: {
    fontSize: '0.875rem',
    color: palette.text.primary,
    lineHeight: 1.57,
    fontWeight: 600,
  },
  body1: {
    fontSize: '1rem',
    lineHeight: 1.75,
    color: palette.text.primary,
  },
  body2: {
    fontSize: '0.875rem',
    color: palette.text.primary,
    lineHeight: 1.75,
  },
  button: {
    fontWeight: 600,
    fontSize: '0.9375rem',
    lineHeight: 1.4,
    textTransform: 'none',
  },
  caption: {
    fontSize: '0.75rem',
    lineHeight: 1.6,
  },
  overline: {
    fontSize: '0.75rem',
    lineHeight: 2,
    fontWeight: 400,
    textTransform: 'none',
  },
});

export default typography;
