import { authClient, setAuthHeader } from 'common/api/common';
import { getJWTToken } from 'common/utils';
import * as urls from '../urls';

export default {
  login(payload) {
    authClient.deleteHeader('Authorization');
    return authClient.post(urls.USER_LOGIN, payload);
  },
  logout() {
    authClient.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return authClient.post(urls.USER_LOGOUT);
  },
  refresh(payload) {
    authClient.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return authClient.post(urls.JWT_REFRESH, payload);
  },
  signup(payload) {
    authClient.deleteHeader('Authorization');
    return authClient.post(urls.USER_SIGNUP, payload);
  },
  newPassword(payload) {
    authClient.deleteHeader('Authorization');
    return authClient.post(urls.NEW_PASSWORD, payload);
  },
  info: {
    create(payload) {
      authClient.setHeader('Authorization', `JWT ${getJWTToken()}`);
      return authClient.post(urls.USER_INFO, payload);
    },
    update(payload) {
      authClient.setHeader('Authorization', `JWT ${getJWTToken()}`);
      return authClient.put(urls.USER_INFO, payload);
    },
  },
  company: {
    list() {
      authClient.setHeader('Authorization', `JWT ${getJWTToken()}`);
      return authClient.get(urls.COMPANY);
    },
  },
};
