import GA from 'ga';
import * as category from '../category';

export const SLIDER_IN_COMPARE_SLIDER_BTN = `${category.SLIDER}InCompareSliderBtn`;
export const SLIDER_IN_TWO_SCREENS_BTN = `${category.SLIDER}InTwoScreensBtn`;
export const SLIDER_IN_FOUR_SCREENS_BTN = `${category.SLIDER}InFourScreensBtn`;

export function sendSliderInCompareSliderBtn() {
  GA.event(category.SLIDER, SLIDER_IN_COMPARE_SLIDER_BTN);
}
export function sendSliderInTwoScreensBtn() {
  GA.event(category.SLIDER, SLIDER_IN_TWO_SCREENS_BTN);
}
export function sendSliderInFourScreensBtn() {
  GA.event(category.SLIDER, SLIDER_IN_FOUR_SCREENS_BTN);
}
