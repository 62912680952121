import { apiAxios } from 'common/api/common';
import { LaborerHistoryResponse } from 'query/laborer/types';

interface Props {
  projectId: number;
  baseTime: string;
  page?: number;
  weak?: boolean;
  status?: string;
  search?: string;
  startAt?: string;
  endAt?: string;
  date?: string;
}

export default {
  async list({ projectId }: Pick<Props, 'projectId'>) {
    const response = await apiAxios.get(`/api/v2/projects/${projectId}/laborers`);
    return response?.data;
  },
  async listHistory({ projectId, baseTime }: Pick<Props, 'projectId' | 'baseTime'>) {
    const response = await apiAxios.get(
      `/api/v2/projects/${projectId}/laborers/location?base_time=${encodeURIComponent(baseTime)}`,
    );
    return response?.data;
  },
  async listHistoryV2({
    projectId,
    page,
    weak,
    status,
    search,
    startAt,
    endAt,
  }: Omit<Props, 'baseTime'>) {
    const response = await apiAxios.get<LaborerHistoryResponse>(
      `/api/v2/projects/${projectId}/laborers/history`,
      { params: { page, weak, status, name: search, start_at: startAt, end_at: endAt } },
    );
    return response?.data;
  },
  async historyExistDate({
    projectId,
    startAt,
    endAt,
  }: Pick<Props, 'projectId' | 'startAt' | 'endAt'>) {
    const response = await apiAxios.get<number[]>(
      `/api/v2/projects/${projectId}/laborers/history/exist-date`,
      { params: { start_at: startAt, end_at: endAt } },
    );
    return response?.data;
  },
  async locationExistDate({ projectId, date }: Pick<Props, 'projectId' | 'date'>) {
    const response = await apiAxios.get<{ dates: string[] }>(
      `/api/v2/projects/${projectId}/laborers/location/exist-date`,
      { params: { date, type: 'person' } },
    );
    return response?.data;
  },
  async historyExcelDownload({
    projectId,
    page,
    weak,
    status,
    search,
    startAt,
    endAt,
  }: Omit<Props, 'baseTime'>) {
    return await apiAxios.get(`/api/v2/projects/${projectId}/laborers/history/excel`, {
      params: { page, weak, status, name: search, start_at: startAt, end_at: endAt },
      responseType: 'blob',
    });
  },
};
