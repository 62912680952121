import { TempFile } from 'query/resource/types';
import { Action } from 'store/types';

export const constants = {
  SET_TEMP_RESOURCE: 'v2/resource/setResource',
  ADD_TEMP_RESOURCES: 'v2/resource/addTempResources',
  DELETE_TEMP_RESOURCE: 'v2/resource/deleteTempResource',
  SET_TEMP_RESOURCES: 'v2/resource/setTempResources',
};

export const actions = {
  setTempResource: (snapshotId, resource) => ({
    type: constants.SET_TEMP_RESOURCE,
    payload: { snapshotId, resource },
  }),
  addTempResources: (snapshotId, resources) => ({
    type: constants.ADD_TEMP_RESOURCES,
    payload: { snapshotId, resources },
  }),
  deleteTempResource: (snapshotId, resource) => ({
    type: constants.DELETE_TEMP_RESOURCE,
    payload: { snapshotId, resource },
  }),
  setTempResources: (snapshotId: number, tempResources: TempFile[]) => ({
    type: constants.SET_TEMP_RESOURCES,
    payload: { snapshotId, tempResources },
  }),
} as { [key: string]: (...args: any) => Action };

type ActionType<T extends keyof typeof actions> = ReturnType<typeof actions[T]>;

export default function reducer(
  state: ResourceData = { snapshotId: undefined, tempResources: [] },
  action: ActionType<
    'deleteTempResource' | 'addTempResources' | 'setTempResource' | 'setTempResources'
  >,
): typeof state {
  switch (action.type) {
    case constants.SET_TEMP_RESOURCE: {
      const { snapshotId, resource } = action.payload;
      return {
        ...state,
        snapshotId,
        tempResources: state.tempResources.map((r) =>
          (r as TempFile)?.tempId === resource.tempId ? resource : r,
        ),
      };
    }
    case constants.DELETE_TEMP_RESOURCE: {
      const { snapshotId, resource } = action.payload;
      return {
        ...state,
        snapshotId,
        tempResources: state.tempResources.filter(
          (r) => (r as TempFile)?.tempId !== resource?.tempId,
        ),
      };
    }
    case constants.ADD_TEMP_RESOURCES: {
      const { snapshotId, resources } = action.payload;
      return {
        ...state,
        snapshotId,
        tempResources: [...state.tempResources, ...resources],
      };
    }
    case constants.SET_TEMP_RESOURCES: {
      const { snapshotId, tempResources } = action.payload;
      return {
        ...state,
        snapshotId,
        tempResources,
      };
    }
    default:
      return state;
  }
}

export type ResourceData = {
  snapshotId: number;
  tempResources?: TempFile[];
};
