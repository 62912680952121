export const VIDEO_MAX_SIZE_IN_MB = 1024 * 1.5;
export const VIDEO_ACCEPT_FORMAT = '.mp4, .mov';
export const PANORAMA_MAX_SIZE_IN_MB = 60;
export const PANORAMA_ACCEPT_FORMAT = '.jpg, .jpeg, .png';
export const PANORAMA_FILE_UPLOAD_MAXIMUM_COUNT = 50;
export const PANORAMA_FILE_UPLOAD_MINIMUM_COUNT = 10;
export const PANORAMA_FILE_UPLOAD_MAXIMUM_SIZE_IN_MB = 50;
export const PANORAMA_FILE_UPLOAD_TOTAL_MAXIMUM_SIZE_IN_MB = 500;
export const BIRDS_EYE_VIEW_MAX_SIZE_IN_MB = 50;
export const BIRDS_EYE_VIEW_ACCEPT_FORMAT = '.jpg, .jpeg, .png';

export const AUTO_DRAWING_ACCEPT_FORMAT = '.dxf';
export const MANUAL_DRAWING_ACCEPT_FORMAT = '.png, .jpg, .pdf, .dxf, .jpeg';
export const DRAWING_MAX_SIZE_IN_MB = 100;

// 한번에 업로드 가능한 최대 개수 : 이미지
export const MAX_IMAGE_UPLOAD_COUNT_AT_ONCE = +process.env.MAX_IMAGE_UPLOAD_COUNT || 5;
export const AERIAL_IMAGE_ACCEPT_FORMAT = {
  DEFAULT: '.jpg, .jpeg,',
  GOLF: '.jpg, .jpeg, .tiff, .tif',
};

export const GCP_ACCEPT_FORMAT = '.csv, .txt';
export const GCP_UPLOAD_COUNT = { min: 0, max: 1 };

export const ORTHOPHOTO_UPLOAD_COUNT = { min: 1, max: 1 };
export const ORTHOPHOTO_ACCEPT_FORMAT = '.tif';
export const DSM_UPLOAD_COUNT = { min: 0, max: 1 };
export const DSM_ACCEPT_FORMAT = '.tif';
export const POINT_CLOUD_UPLOAD_COUNT = { min: 0, max: 5 };
export const POINT_CLOUD_ACCEPT_FORMAT = '.las';
export const MESH_UPLOAD_COUNT = { min: 0, max: 1 };
export const MESH_ACCEPT_FORMAT = '.fbx';
export const MESH_MAX_SIZE_IN_MB = 500;

export const BIM_UPLOAD_COUNT = { min: 0, max: undefined };
export const BIM_ACCEPT_FORMAT = '.rvt, .ifc';
export const BIM_MAX_SIZE_IN_MB = undefined;

export const PLAN_LEVEL_UPLOAD_COUNT = { min: 0, max: undefined };
export const PLAN_LEVEL_ACCEPT_FORMAT = '.tif';
export const PLAN_LEVEL_MAX_SIZE_IN_MB = 30 * 1024;

export const DTM_UPLOAD_COUNT = { min: 0, max: undefined };
export const DTM_ACCEPT_FORMAT = '.tif';
export const DTM_MAX_SIZE_IN_MB = undefined;

export const ETC_UPLOAD_COUNT = { min: 0, max: 10 };
export const ETC_ACCEPT_FORMAT = '*.*';
export const ETC_MAX_SIZE_IN_MB = undefined;
export const QR_CODE_DOWNLOAD_FILE_TYPE = 'image/jpeg';

export const DEFAULT_IMAGE_UPLOAD_COUNT = { min: 30, max: 1000 };

export const STRATUM_MAX_LINKING_COUNT = 5;

export const ISSUE_MAIN_IMAGE_ACCEPT_FORMAT = '.jpg, .jpeg, .png';
export const ISSUE_COMMENT_MAX_SIZE_IN_MB = 1024;
export const ISSUE_COMMENT_UPLOAD_COUNT = { min: 0, max: 10 };

export const ANNOTATION_CSV_UPLOAD_COUNT = { min: 0, max: undefined };
export const ANNOTATION_CSV_ACCEPT_FORMAT = '.csv';
export const ANNOTATION_CSV_MAX_SIZE_IN_MB = 1024;

export const GCP_IMAGE_ACCEPT_FORMAT = '.jpg, .jpeg, .png';
export const GCP_IMAGE_MAX_SIZE_IN_MB = 50;

export const NDVI_UPLOAD_COUNT = { min: 0, max: 20 };
export const NDVI_ACCEPT_FORMAT = '.tif, .tiff';
export const NDVI_MAX_SIZE_IN_MB = undefined;

export const NDMI_UPLOAD_COUNT = { min: 0, max: 20 };
export const NDMI_ACCEPT_FORMAT = '.tif, .tiff';
export const NDMI_MAX_SIZE_IN_MB = undefined;

export const POLYGON_VECTOR_UPLOAD_COUNT = { min: 0, max: 20 };
export const POLYGON_VECTOR_ACCEPT_FORMAT = '.dxf';
export const POLYGON_VECTOR_MAX_SIZE_IN_MB = 2;

export const INDOOR_VIDEO_ACCEPT_FORMAT = '.mp4';

export const FLOOR_PLAN_ACCEPT_FORMAT = '.pdf, .png, .jpg, .jpeg, .dxf';
export const FLOOR_PLAN_MAX_SIZE_IN_MB = 50;
export const FLOOR_PLAN_UPLOAD_COUNT = { min: 0, max: 1 };

export const SR_UPLOAD_COUNT = { min: 0, max: 1 };
export const SR_ACCEPT_FORMAT = '.tif, .tiff';
export const SR_MAX_SIZE_IN_MB = undefined;

export const PHOTO_BOX_MEDIA_ACCEPT_FORMAT = '.jpg, .jpeg, .png, .mp4, .mov';
export const PHOTO_BOX_IMAGE_ACCEPT_FORMAT = '.jpg, .jpeg, .png';
export const PHOTO_BOX_VIDEO_ACCEPT_FORMAT = '.mp4, .mov';
export const PHOTO_BOX_DROPZONE_MEDIA_ACCEPT_FORMAT = ['jpg', 'jpeg', 'png', 'mp4', 'mov'];
export const PHOTO_BOX_IMAGE_MAX_SIZE_IN_MB = 50;
export const PHOTO_BOX_VIDEO_MAX_SIZE_IN_MB = 2 * 1024;
