import Map from 'components/common/2D/Map';
import OrthophotoLayer from 'components/common/2D/layers/OrthophotoLayer';
import DrawingVectorEditorLayer from 'components/common/2D/layers/annotation/DrawingVectorEditorLayer';
import SatelliteLayer from 'components/common/2D/layers/satellite/SatelliteLayer';
import { LeafletMouseEvent } from 'leaflet';
import React from 'react';
import { useMapEvents } from 'react-leaflet';
import EditorPageLayout from 'styleComponents/editorPageLayout';
import useDrawingVectorEdit from './useDrawingVectorEdit';

export default function DrawingVectorEditorMap() {
  return (
    <EditorPageLayout.MapArea>
      <Map>
        <MapEventHandlers />
        <SatelliteLayer />
        <OrthophotoLayer moveCenter={false} />
        <DrawingVectorEditorLayer />
      </Map>
    </EditorPageLayout.MapArea>
  );
}

function MapEventHandlers() {
  const { setSelectedBulkPolygonTempId } = useDrawingVectorEdit();
  const map = useMapEvents({
    click(e: LeafletMouseEvent) {
      if (
        e.originalEvent.target instanceof HTMLElement &&
        (e.originalEvent.target.classList.contains('leaflet-marker-pane') ||
          e.originalEvent.target.classList.contains('leaflet-tooltip-pane') ||
          e.originalEvent.target.classList.contains('leaflet-popup-pane') ||
          e.originalEvent.target.classList.contains('leaflet-interactive'))
      ) {
        return;
      }
      if (
        e.originalEvent.target instanceof HTMLElement &&
        e.originalEvent.target.classList.contains('leaflet-container')
      ) {
        setSelectedBulkPolygonTempId(null);
      }
    },
    mousedown(e: LeafletMouseEvent) {
      if (e.originalEvent.button != 0) return; // if not primary button
      if (!(e.originalEvent.target instanceof HTMLElement)) return;

      if (
        !e.originalEvent.target.classList.contains('leaflet-container') &&
        !e.originalEvent.target.classList.contains('leaflet-image-layer')
      ) {
        map.dragging.disable();
      }
    },
    mouseup(e: LeafletMouseEvent) {
      if (e.originalEvent.button != 0) return; // if not primary button

      map.dragging.enable();
    },
  });
  return null;
}
