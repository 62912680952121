import { PlanedVolumeNumberRegex, inputtingNumberRegex } from 'common/policies/limit';
import { PlanLevelVolumeSnapshot, PlanedVolumeAnnotation } from 'query/earthworkTable/types';

export const getAmountsExceptFillAndSoil = (annotation: PlanedVolumeAnnotation) => {
  return annotation?.amounts?.filter((x) => x?.name !== 'fill' && x?.name !== 'soil');
};

export const getAmountsExceptFill = (annotation: PlanedVolumeAnnotation) => {
  return annotation?.amounts?.filter((x) => x?.name !== 'fill');
};

export const orderByTakeDateDesc = (a: PlanLevelVolumeSnapshot, b: PlanLevelVolumeSnapshot) => {
  return a.takeDate === b.takeDate ? -a.id + b.id : -a.takeDate.localeCompare(b.takeDate);
};

export const formatTableCell = (value: number | string) => {
  if (!value) return value;
  if (isNaN(+value)) return value;

  /** 반올림, 0, -0 처리 */
  let roundValue = Math.round(+value);
  roundValue = roundValue === 0 || roundValue === -0 ? 0 : roundValue;
  return roundValue?.toLocaleString();
};

/**
 *
 * @param x
 * @returns 양수, 소숫점 둘째자리까지 입력 가능, 3. 처럼 입력 중인 값이 있을 경우 3. 으로 반환
 */
export const filterInvalidTwoDecimalNumber = (x) => x?.match(PlanedVolumeNumberRegex)?.[0];
export const isInputtingNumber = (x) => x.toString()?.match(inputtingNumberRegex)?.[0];
