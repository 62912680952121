import { EARTHWORK_TABLE } from 'ga/category';
import GA from 'ga';

export const sendEventEarthworkTable = (action: string, label?: string) => {
  GA.event(EARTHWORK_TABLE, action, label);
};

const EARTHWORK_TABLE_CAL_BTN = `${EARTHWORK_TABLE}CalBtn`;
const EARTHWORK_TABLE_EXPORT_BTN = `${EARTHWORK_TABLE}ExportBtn`;
const EARTHWORK_TABLE_PLANNED_VOLUME_EDIT = `${EARTHWORK_TABLE}PlannedVolumeEdit`;

export const sendEarthworkTableCalBtn = () => {
  sendEventEarthworkTable(EARTHWORK_TABLE_CAL_BTN);
};
export const sendEarthworkTableExportBtn = () => {
  sendEventEarthworkTable(EARTHWORK_TABLE_EXPORT_BTN);
};
export const sendEarthworkTablePlannedVolumeEdit = () => {
  sendEventEarthworkTable(EARTHWORK_TABLE_PLANNED_VOLUME_EDIT);
};
