import { atom } from 'jotai';
import { LatLngLiteral } from 'leaflet';
import { VehicleBaseDate, VehicleSlider } from 'store/data/types';

export const vehicleAtom = {
  expanded: atom<boolean>(false),
  checked: atom<boolean>(false),
  baseDate: atom<VehicleBaseDate>(new Date(new Date().setHours(0, 0, 0, 0))),
  slider: atom<VehicleSlider>(0),
  focused: atom<{ latLng: LatLngLiteral; id?: number; zoom: number } | false>(false),
};

export const vehicleDrivingRoute = {
  drivingHistoryId: atom<string>(''),
  openDetail: atom<boolean>(false),
};
