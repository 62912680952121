import { CoreEngine } from 'common/types';
import { LatLngLiteral } from 'leaflet';
import { MapProvider } from 'query/project/types';

export const NDVI_WHOLE_ID = 'whole';
export const NDVI_VARI_ID = 'vari';
export type Visibilities = {
  scaleControl?: boolean;
  coordinateControl?: boolean;
  pointCloud?: boolean;
  planLevel3D?: { all?: boolean; [id: number]: boolean };
  dsm3D?: { all?: boolean; [id: number]: boolean };
  mesh?: boolean;
  meshOnPointCloud?: boolean;
  bim?: { all?: boolean; [id: number]: boolean };
  bimOnPointCloud?: { all?: boolean; [id: number]: boolean };
};

export type Opacities = {
  planLevel: { [id: number]: number };
  drawings: { [id: number]: number };
  dsm: number;
  stratums: { [id: number]: number };
  planLevel3D: { [id: number]: number };
  dsm3D?: { [id: number]: number };
  bimOnPointCloud?: { [id: number]: number };
};

export type SnapshotData = {
  visibilities?: Visibilities;
  viewport?: { zoom?: number; center?: LatLngLiteral; selectedId?: number };
  opacities?: Opacities;
};

export type Snapshot = {
  id?: number;
  meshUid?: string;
  zoneId?: number;
  takeDate?: string;
  description?: string;
  coreEngine?: CoreEngine;
  data?: any;
  isPreprocessed?: boolean;
  latestProcessedSnapshotTakeDate?: string;
  processingStatus?:
    | 'IDLE'
    | 'PAUSE'
    | 'PAUSE_REQUEST'
    | 'RUNNING'
    | 'RUNNING_REQUEST'
    | 'DONE'
    | 'ERROR';
  orthophotoThumbnailUrl?: string;
  universalData?: {
    metadataJsonUrl?: string;
    pointCloudData?: { jsonFilename?: string; rootPath?: string; tokenQueryString?: string };
  };
  uiDisableStatus?: {
    threeDMap?: {
      isMainBtnDisabled?: boolean;
      isMeshBtnDisabled?: boolean;
      isPlanlevelBtnDisabled?: boolean;
      isPointcloudBtnDisabled?: boolean;
      isBimBtnDisabled?: boolean;
    };
    sideMenu?: {
      isGcpDisabled?: boolean;
      isPlanLevelDisabled?: boolean;
      isDsmDisabled?: boolean;
      isOrthophotoDisabled?: boolean;
    };
  };
  mapProvider?: MapProvider; // 공유하기 페이지에서 사용하기 위함
  isRelationRequest?: boolean;
  hasGcpMark?: boolean;
};

export type CadastralParcel = {
  pnu: string;
  compensationStatus: CompensationStatus;
  data?: {
    geometry?: { coordinates?: any; type?: 'MultiPolygon' };
    properties?: {
      addr: string;
      bonbun: string;
      bubun: string;
      gosiYear: string;
      gosiMonth: string;
      jibun: string;
      jiga: string;
      pnu: string;
    };
    id: string;
    type: 'Feature';
  };
  focused?: boolean;
};

export enum CompensationStatus {
  Required = 'required',
  Pending = 'pending',
  Done = 'done',
  Undefined = 'undefined',
}

export const isDone = (s: Snapshot) => s?.processingStatus === 'DONE';
export const isRunning = (s: Snapshot) =>
  ['RUNNING', 'RUNNING_REQUEST'].includes(s?.processingStatus);
export const isIdle = (s: Snapshot) =>
  ['IDLE', 'PAUSE', 'PAUSE_REQUEST'].includes(s?.processingStatus);
export const isError = (s: Snapshot) => s?.processingStatus === 'ERROR';
