import axios from 'axios';
import { apiAxios } from 'common/api/common';
import * as urls from 'common/urls';
import { convertKeysToCamelCase } from 'common/utils';
import { JoinUser, UserInfo } from 'store/data/types';
import { User } from './types';

export default {
  async create(data: JoinUser) {
    const url = `${urls.baseUrl}/auth/signup`;
    const response = await axios.post(url, data);
    return convertKeysToCamelCase(response?.data);
  },

  async login(data: { email: string; password: string }) {
    const url = `${urls.baseUrl}/auth/login`;
    const payload = { ...data, service: 'cloud' };
    const response = await axios.post(url, payload);
    return convertKeysToCamelCase(response?.data);
  },

  async refresh(refreshToken: string) {
    const url = `${urls.baseUrl}/auth/refresh`;
    const response = await axios.post(url, { refresh: refreshToken });
    return convertKeysToCamelCase(response?.data);
  },

  async readInfo() {
    const response = await apiAxios.get<User>('/auth/info');
    return response?.data;
  },

  async updateInfo(data: UserInfo) {
    const response = await apiAxios.put<User>('/auth/info', data);
    return response?.data;
  },

  async listCompany() {
    const response = await apiAxios.get('/auth/company');
    return response?.data?.results || [];
  },

  async createNewPassword(email: string) {
    const url = `${urls.baseUrl}/auth/password/new`;
    const response = await axios.post(url, { email });
    return convertKeysToCamelCase(response?.data);
  },
};
