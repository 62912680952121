import { nls } from 'locale/language';
import deeplinkGuardLogo from '../../assets/images/deeplinkGuardLogo.svg';
import deeplinkMobileLogo from '../../assets/images/deeplinkMobileLogo.svg';

export interface DeeplinkSettings {
  path: string;
  packageName: string;
  playStoreUrl: string;
  appStoreUrl: string;
  scheme: string;
  icon: string;
  description: string;
}

export const guard: DeeplinkSettings = {
  path: '/guard',
  packageName: process.env.GUARD_PACKAGE_NAME,
  scheme: 'meissa.meiday',
  playStoreUrl: 'https://play.google.com/store/apps/details?id=com.meissa.meiday',
  appStoreUrl: 'https://apps.apple.com/us/app/meiday-for-worker-safety/id1621009357',
  icon: deeplinkGuardLogo,
  description: nls.deeplinkGuardServiceMessage(),
};

export const mobile: DeeplinkSettings = {
  path: '/mobile',
  packageName: process.env.MOBILE_PACKAGE_NAME,
  scheme: 'meissa.mobile',
  playStoreUrl: 'https://play.google.com/store/apps/details?id=com.meissa.meissa_lite',
  appStoreUrl: 'https://apps.apple.com/us/app/meissa-lite/id1575737553',
  icon: deeplinkMobileLogo,
  description: nls.deeplinkMobileServiceMessage(),
};
