import Axios from 'axios';
import { Api } from 'common/api';
import { DEFAULT_POLLING_INTERVAL, DRAWING_POLLING_LONG_INTERVAL } from 'common/policies/request';
import requestApi from 'common/requestApi';
import { isPositioning, isVector } from 'query/resource/typeGuards';
import { delay } from 'redux-saga';
import { all, call, cancelled, put, race, take, takeLatest } from 'redux-saga/effects';
import actions, { ActionType } from './actions';
import constants from './constants';
import { Drawing, isConversion, isEnhanceQuality } from './types';

function* createDrawings(api: Api, { payload: data }: ActionType<'createDrawings'>) {
  const { drawings, ...rest } = data;

  let index = 0;

  const source = Axios.CancelToken.source();
  const cancelToken = source.token;
  while (index < drawings?.length) {
    const { cancellation } = yield race({
      success: call(createDrawing, api, { ...rest, drawing: drawings[index], cancelToken }),
      cancellation: take(constants.CANCEL_CREATE_DRAWINGS),
    });

    if (cancellation) {
      source.cancel();
      return;
    }
    index += 1;
  }
}

function* createDrawing(api: Api, { snapshotId, coordinate, type, drawing, cancelToken }) {
  try {
    yield put(actions.setUploadingDrawing({ ...drawing, status: 'LOADING' }));

    const formData = new FormData();
    formData.append('file', drawing?.file);
    formData.append('type', type);
    formData.append('coordinate', coordinate);

    const { isFail, responseData } = yield requestApi(
      api.drawing.create,
      {
        snapshot_id: snapshotId,
        formData,
        cancelToken,
      },
      { turnOffCaseConversion: true }, // cancelToken이 깨지는 현상 수정
    );

    if (isFail) {
      yield put(actions.setUploadingDrawing({ ...drawing, ...responseData, status: 'ERROR' }));
      return;
    }
    yield put(actions.setUploadingDrawing({ ...drawing, ...responseData, status: 'SUCCESS' }));
  } finally {
    if (yield cancelled()) {
      yield put(actions.setUploadingDrawing({ ...drawing, status: 'NONE' }));
    }
  }
}
function* readDrawing(api: Api, { payload: data }: ActionType<'readDrawing'>) {
  const { isFail, responseData } = yield requestApi(api.drawing.read, data);

  if (isFail) {
    return;
  }
  yield put(actions.setDrawing({ item: responseData }));
}
function* deleteDrawing(api: Api, { payload: data }: ActionType<'deleteDrawing'>) {
  const { isFail } = yield requestApi(api.drawing.delete, data);

  if (isFail) {
    return;
  }

  yield put(actions.cancelListDrawings());
  yield put(actions.listDrawings(data.snapshotId, false));
}

function* updatePositioningDrawing(
  api: Api,
  { payload: data }: ActionType<'updatePositioningDrawing'>,
) {
  const { isFail } = yield requestApi(api.drawing.update, data);
}

function* abortDrawing(api: Api, { payload: data }: ActionType<'abortDrawing'>) {
  const { snapshotId, drawingId } = data;
  const { isFail } = yield requestApi(api.drawing.abort, data);

  if (isFail) {
    return;
  }

  yield put(actions.readDrawing(snapshotId, drawingId));
}

function* retryDrawing(api: Api, { payload: data }: ActionType<'retryDrawing'>) {
  const { snapshotId, drawingId } = data;
  const { isFail } = yield requestApi(api.drawing.retry, data);

  if (isFail) {
    return;
  }

  yield put(actions.readDrawing(snapshotId, drawingId));
}

function* listDrawings(api: Api, action: ActionType<'listDrawings'>) {
  // 초기 요청과 폴링상태 분리 : 초기 요청시에는 데이터 초기화 필요, 폴링중에는 데이터 초기화하면 안됨
  const { payload: data } = action;
  if (data?.initial) {
    yield put(actions.setDrawings({ status: { list: 'LOADING' }, data: null }));
  } else {
    yield put(actions.setDrawings({ status: { list: 'LOADING' } }));
  }
  const { isFail, responseData } = yield requestApi(api.drawing.list, data);

  if (isFail) {
    yield put(actions.setDrawings({ status: { list: 'ERROR' }, data: [] }));
    return;
  }

  const drawings = responseData.count === 0 ? [] : responseData.results;

  yield put(actions.setDrawings({ status: { list: 'SUCCESS' }, data: [...drawings] }));
  if (needToReadDrawings(drawings)) {
    const { stop } = yield race({
      stop: take(constants.CANCEL_LIST_DRAWINGS),
      delay: call(delay, getDelayTime(drawings)),
    });

    // DRAWING_POLLING_DELAY_TIME초가 되기 전에 취소 요청이 오면 api request 하지 않음.
    if (stop) {
      return;
    }

    // cancel 요청이 올경우 현재 task 자동 취소
    yield race({
      stop: take(constants.CANCEL_LIST_DRAWINGS),
      delay: put(actions.listDrawings(data.snapshotId, false)),
    });
  }
}

export default function* drawingSaga(api: Api) {
  yield all([
    takeLatest(constants.CREATE_DRAWINGS, createDrawings.bind(undefined, api)),
    takeLatest(constants.READ_DRAWING, readDrawing.bind(undefined, api)),
    takeLatest(constants.DELETE_DRAWING, deleteDrawing.bind(undefined, api)),
    takeLatest(constants.UPDATE_POSITIONING_DRAWING, updatePositioningDrawing.bind(undefined, api)),
    takeLatest(constants.RETRY_DRAWING, retryDrawing.bind(undefined, api)),
    takeLatest(constants.ABORT_DRAWING, abortDrawing.bind(undefined, api)),
    takeLatest(constants.LIST_DRAWINGS, listDrawings.bind(undefined, api)),
  ]);
}

function needToReadDrawings(drawings: Drawing[]): boolean {
  return drawings?.some((x) => isConversion(x) || isEnhanceQuality(x));
}

function getDelayTime(drawings: Drawing[]): number {
  // 자동 래스터만 변환중일 경우는 지연 시간을 더 크게 둔다
  return drawings?.some((x) => isConversion(x) && (isVector(x) || isPositioning(x)))
    ? DEFAULT_POLLING_INTERVAL
    : DRAWING_POLLING_LONG_INTERVAL;
}
