import { ImgArchiveStatus, UploadStatus } from 'common/types';
import { Permission } from 'query/permission/types';
import { MapProvider } from 'query/project/types';
import { Dispatch, SetStateAction } from 'react';

export type LoadingStatus = 'NONE' | 'LOADING' | 'SUCCESS' | ['PARTIAL', number, number] | 'ERROR';

export type ApiError = {
  messageCode: string;
  status: number;
  config: any;
};

export type LoginUser = {
  isOwner: boolean;
  validateIn: number;
  refresh: string;
  access: string;
  email?: string;
};

export type Users = User[];

export interface User {
  email?: string;
  password?: string;
  fullName?: string;
  company?: string;
  jobTitle?: string;
}

export interface JoinUser extends User {
  inviteCode?: string;
  agreement?: boolean;
  confirmPassword?: string;
}

export interface UserInfo extends User {
  newPassword?: string;
  confirmPassword?: string;
}

export type Projects = Project[];

export type Project = {
  id?: number;
  name?: string;
  description?: string;
  constructionDate?: string;
  completedDate?: string;
  state?: boolean | null;
  owner?: string;
  snapshots?: number[];
  coordinate?: string;
  permissionInfo?: Permission;
  latestThumbnailUrl?: string;
  latestOrthophotoBoundingBox?: { minx: string; maxx: string; miny: string; maxy: string };
  isExpired?: boolean;
  zoneIds?: number[];
  mapProvider?: MapProvider;
  otp?: string;
  lengthUnit?: ProjectUnit;
  areaUnit?: ProjectUnit;
  volumeUnit?: ProjectUnit;
  processedSnapshotCount?: number;
  latestTakeDate?: string;
  data?: ProjectData;
};

export type ProjectUnit = {
  id: number;
  name: string;
  coefficient: number;
  type: string;
  default?: boolean;
};

export type ProjectData = {
  logoutByDistance: number;
};

export enum SnapshotFileType {
  POINT_CLOUD = 'POINT_CLOUD',
  ORTHOPHOTO = 'ORTHOPHOTO',
  ENGINE_ORTHOPHOTO = 'ENGINE_ORTHOPHOTO',
  DSM = 'DSM',
  ENGINE_DSM = 'ENGINE_DSM',
  AERIAL_IMAGE = 'AERIAL_IMAGE',
  MESH = 'MESH',
  GCP = 'GCP',
  ETC = 'ETC',
  PLAN_LEVEL = 'PLAN_LEVEL',
  AERIALIMAGE_ARCHIVE = 'AERIALIMAGE_ARCHIVE',
  STRATUM = 'STRATUM',
  BIM = 'BIM',
  ENGINE_MESH_GLB = 'ENGINE_MESH_GLB',
}

export interface SnapshotFile {
  status: UploadStatus | ImgArchiveStatus;
  id?: number;
  file?: File;
  name?: string;
  size?: number;
  key?: number;
  type?: SnapshotFileType;
  data?: {
    tmsPath?: string;
    processed?: boolean;
    binaryFileInfo?: {
      binFilePath?: string;
      border?;
      coordinate?;
      height?: number;
      targetHeight?: number;
      targetWidth?: number;
      width?: number;
    };
    tmsMeta?: {
      zoomLevel: {
        min: number;
        max: number;
      };
      tileMap: {
        boundingBox: {
          maxx: string;
          maxy: string;
          minx: string;
          miny: string;
        };
      };
    };
    fileUploadIndex?: number;
    volume?: {
      [snapshotId: number]: {
        cutVolume: number;
        fillVolume: number;
        gid: number;
      };
    };
    range?: {
      max: number;
      min: number;
    };
    bimInfo?: {
      /** Cesium */
      tileSetUrl?: string;
      ionAccessToken?: string;

      glbFilePath?: string;
      accessToken?: string;
      isNewConverter?: boolean;
    };
    positionInfo?: {
      isManual?: boolean;
      position;
      rotation;
    };
    color?: string;
  };
  binaryUrl?: string;
  tmsUrl?: string;
  tmsUrlToken?: string;
  snapshotId?: string | number;
  alias?: string;
  fileUrl?: string;
  metaUrl?: string;
  errorCode?: string; //imageArchive
}

export type Proj4Definition = { code: string; proj4: string }[];
export type CombinedState = {
  apiError: ApiError;
};

export type TokenObj = {
  access: string;
  refresh: string;
  validateIn?: number;
};

export type Invitation = {
  email: string;
  code: string;
};

export type ProjectParticipants = {
  invites: ProjectUsers;
  projectUsers: ProjectUsers;
  status: {
    invite: LoadingStatus;
    reinvite: LoadingStatus;
    remove: LoadingStatus;
  };
};

export type ProjectUser = {
  user?: User;
  projectId?: number; // project id
  permission?: string;
  dateCreate?: string; // 2021-08-27T07:45:22.773988Z
  id?: number; // projectuser id
  email?: string; // only invitee
  permissionChangeOptions?: Permission[] | null;
};

export type ProjectUsers = ProjectUser[];

export interface Company {
  id: number;
  name: string;
}

export type Companies = Company[];

export interface Coordinate {
  code: string | number;
  name: string;
  proj4: string;
}

export type Coordinates = Coordinate[];

export type LaborerTabValue = 'LABORER_LOCATION_LIST' | 'LABORER_LOCATION_HISTORY_LIST';
export type SetLaborerTabValue = Dispatch<SetStateAction<LaborerTabValue>>;
export type LaborerBaseDate = Date;
export type SetLaborerBaseDate = Dispatch<SetStateAction<LaborerBaseDate>>;
export type LaborerSlider = number;
export type SetLaborerSlider = Dispatch<SetStateAction<LaborerSlider>>;

export type VehicleTabValue = 'VEHICLE_LOCATION_LIST' | 'VEHICLE_LOCATION_HISTORY_LIST';
export type SetVehicleTabValue = Dispatch<SetStateAction<VehicleTabValue>>;
export type VehicleBaseDate = Date;
export type SetVehicleBaseDate = Dispatch<SetStateAction<VehicleBaseDate>>;
export type VehicleSlider = number;
export type SetVehicleSlider = Dispatch<SetStateAction<VehicleSlider>>;
