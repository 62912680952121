import { Reducer } from 'redux';
import * as constants from 'store/data/constants';
import { ApiError } from 'store/data/types';
import { Action } from 'store/types';

const reducer: Reducer<ApiError, Action<ApiError>> = (state = null, action) => {
  switch (action.type) {
    case constants.SET_API_ERROR:
      return action.payload;
    case constants.CLEAR_API_ERROR:
      return null;
    default:
      return state;
  }
};

export default reducer;
