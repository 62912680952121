import { MediaListParams, PhotoBoxAlbumDetailListParams } from 'query/photoBox/types';

export const keys = {
  getMediaList: ({ lastMediaId, limit, projectId, type, zoneId }: MediaListParams) => [
    'photoBox',
    'media',
    'list',
    lastMediaId,
    limit,
    projectId,
    type,
    zoneId,
  ],
  getAlbumList: (projectId: number) => ['photoBox', 'album', 'list', projectId],
  getAlbumDetailList: ({ albumId, lastMediaId, limit }: PhotoBoxAlbumDetailListParams) => [
    'photoBox',
    'albumDetail',
    'list',
    albumId,
    lastMediaId,
    limit,
  ],
};
