import { defaultClient as client } from 'common/api/common';
import { getJWTToken } from 'common/utils';

export default {
  create(payload) {
    const { snapshot_id, ...rest } = payload;
    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.post(`/v3/snapshots/${snapshot_id}/aerialimages`, rest, {
      timeout: 60_000,
    });
  },
  read(payload) {
    const { snapshot_id, aerial_image_id } = payload;

    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.get(`/v3/snapshots/${snapshot_id}/aerialimages/${aerial_image_id}`);
  },
  update(payload) {
    const { snapshot_id, aerial_image_id } = payload;

    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.patch(`/v3/snapshots/${snapshot_id}/aerialimages/${aerial_image_id}`, {
      ...payload,
    });
  },
  delete(payload) {
    const { snapshot_id, aerial_image_id } = payload;

    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.delete(`/v3/snapshots/${snapshot_id}/aerialimages/${aerial_image_id}`);
  },
  list(payload) {
    const { snapshot_id } = payload;

    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.get(`/v3/snapshots/${snapshot_id}/aerialimages`);
  },
  archive({ snapshot_id }) {
    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.get(`/v3/snapshots/${snapshot_id}/aerialimages/archive`);
  },
};
