import { defaultClient as client, multiClient } from 'common/api/common';
import { getJWTToken } from 'common/utils';

export default {
  create(payload) {
    const { snapshot_id, formData, cancelToken } = payload;
    multiClient.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return multiClient.post(`/v3/snapshots/${snapshot_id}/drawings`, formData, { cancelToken });
  },
  read(payload) {
    const { snapshot_id, drawing_id } = payload;
    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.get(`/v3/snapshots/${snapshot_id}/drawings/${drawing_id}`);
  },
  delete(payload) {
    const { snapshot_id, drawing_id } = payload;
    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.delete(`/v3/snapshots/${snapshot_id}/drawings/${drawing_id}`);
  },
  update(payload) {
    const { drawing_id, data_id, ...rest } = payload;
    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.patch(`/v3/drawings/${drawing_id}/positioning/${data_id}`, rest);
  },
  abort(payload) {
    const { snapshot_id, drawing_id } = payload;
    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.post(`/v3/snapshots/${snapshot_id}/drawings/${drawing_id}/abort`);
  },
  retry(payload) {
    const { snapshot_id, drawing_id } = payload;
    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.post(`/v3/snapshots/${snapshot_id}/drawings/${drawing_id}/retry`);
  },
  list(payload) {
    const { snapshot_id } = payload;
    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.get(`/v3/snapshots/${snapshot_id}/drawings`);
  },
};
