import GA from 'ga';
import { TWO_D_COMPARE } from 'ga/category';

export const TWO_D_COMPARE_DRAWING_ADD_BTN = `${TWO_D_COMPARE}DrawingAddBtn`;
export const TWO_D_COMPARE_DRAWING_ADD_MODAL_AUTO_POS_UP_BTN = `${TWO_D_COMPARE}DrawingAddModalAutoPosUpBtn`;
export const TWO_D_COMPARE_DRAWING_ADD_MODAL_MANUAL_POS_UP_BTN = `${TWO_D_COMPARE}DrawingAddModalManualPosUpBtn`;
export const TWO_D_COMPARE_DRAWING_LINK_BTN = `${TWO_D_COMPARE}DrawingLinkBtn`;
export const TWO_D_COMPARE_DRAWING_POS_BTN = `${TWO_D_COMPARE}DrawingPosBtn`;
export const TWO_D_COMPARE_DRAWING_FOLDER_ADD_BTN = `${TWO_D_COMPARE}DrawingFolderAddBtn`;
export const TWO_D_COMPARE_DRAWING_FILE_ADD_FOLDER_BTN = `${TWO_D_COMPARE}DrawingFileAddFolderBtn`;
export const TWO_D_COMPARE_DRAWING_NAME_EDIT = `${TWO_D_COMPARE}DrawingNameEdit`;
export const TWO_D_COMPARE_DRAWING_MOVE_BTN = `${TWO_D_COMPARE}DrawingMoveBtn`;
export const TWO_D_COMPARE_DRAWING_DRAG = `${TWO_D_COMPARE}DrawingDrag`;
export const TWO_D_COMPARE_DRAWING_SEL_BTN = `${TWO_D_COMPARE}DrawingSelBtn`;
export const TWO_D_COMPARE_DRAWING_VIEW_ALL_CBOX = `${TWO_D_COMPARE}DrawingViewAllCbox`;
export const TWO_D_COMPARE_DRAWING_VIEW_CBOX = `${TWO_D_COMPARE}DrawingViewCbox`;

export const TWO_D_COMPARE_EARTH_LEVEL_ADD_BTN = `${TWO_D_COMPARE}EarthLevelAddBtn`;
export const TWO_D_COMPARE_EARTH_LEVEL_LINK_BTN = `${TWO_D_COMPARE}EarthLevelLinkBtn`;
export const TWO_D_COMPARE_EARTH_LEVEL_VIEW_ALL_CBOX = `${TWO_D_COMPARE}EarthLevelViewAllCbox`;
export const TWO_D_COMPARE_EARTH_LEVEL_VIEW_CBOX = `${TWO_D_COMPARE}EarthLevelViewCbox`;
const TWO_D_COMPARE_EARTH_LEVEL_OPACITY_SLIDER = `${TWO_D_COMPARE}EarthLevelOpacitySlider`;
const TWO_D_COMPARE_EARTH_LEVEL_OPACITY_INPUT = `${TWO_D_COMPARE}EarthLevelOpacityInput`;
const TWO_D_COMPARE_EARTH_LEVEL_ELEVATION_SLIDER = `${TWO_D_COMPARE}EarthLevelElevationSlider`;
const TWO_D_COMPARE_EARTH_LEVEL_ELEVATION_INPUT = `${TWO_D_COMPARE}EarthLevelElevationInput`;

export const TWO_D_COMPARE_PLAN_LEVEL_ADD_BTN = `${TWO_D_COMPARE}PlanLevelAddBtn`;
export const TWO_D_COMPARE_PLAN_LEVEL_COMPARE_WITH_DSM_TGL = `${TWO_D_COMPARE}PlanLevelCompareWithDSMTgl`;
export const TWO_D_COMPARE_PLAN_LEVEL_LINK_BTN = `${TWO_D_COMPARE}PlanLevelLinkBtn`;
const TWO_D_COMPARE_PLAN_LEVEL_OPACITY_SLIDER = `${TWO_D_COMPARE}PlanLevelOpacitySlider`;
const TWO_D_COMPARE_PLAN_LEVEL_OPACITY_INPUT = `${TWO_D_COMPARE}PlanLevelOpacityInput`;
const TWO_D_COMPARE_PLAN_LEVEL_ELEVATION_SLIDER = `${TWO_D_COMPARE}PlanLevelElevationSlider`;
const TWO_D_COMPARE_PLAN_LEVEL_ELEVATION_INPUT = `${TWO_D_COMPARE}PlanLevelElevationInput`;
export const TWO_D_COMPARE_PLAN_LEVEL_VIEW_ALL_CBOX = `${TWO_D_COMPARE}PlanLevelViewAllCbox`;
export const TWO_D_COMPARE_PLAN_LEVEL_VIEW_CBOX = `${TWO_D_COMPARE}PlanLevelViewCbox`;

const TWO_D_COMPARE_PLAN_LEVEL_APPLY_DATE = `${TWO_D_COMPARE}PlanLevelApplyDate`;
const TWO_D_COMPARE_PLAN_LEVEL_PANEL_DEL_BTN = `${TWO_D_COMPARE}PlanLevelPanelDelBtn`;
const TWO_D_COMPARE_PLAN_LEVEL_PANEL_DOWN_BTN = `${TWO_D_COMPARE}PlanLevelPanelDownBtn`;

export const sendTwoDCompareDrawingLinkBtn = () =>
  GA.event(TWO_D_COMPARE, TWO_D_COMPARE_DRAWING_LINK_BTN);
export const sendTwoDComparePlanLevelLinkBtn = () =>
  GA.event(TWO_D_COMPARE, TWO_D_COMPARE_PLAN_LEVEL_LINK_BTN);
export const sendTwoDCompareEarthLevelLinkBtn = () =>
  GA.event(TWO_D_COMPARE, TWO_D_COMPARE_EARTH_LEVEL_LINK_BTN);
export const sendTwoDComparePlanLevelOpacitySlider = () =>
  GA.event(TWO_D_COMPARE, TWO_D_COMPARE_PLAN_LEVEL_OPACITY_SLIDER);
export const sendTwoDComparePlanLevelOpacityInput = () =>
  GA.event(TWO_D_COMPARE, TWO_D_COMPARE_PLAN_LEVEL_OPACITY_INPUT);
export const sendTwoDComparePlanLevelElevationSlider = () =>
  GA.event(TWO_D_COMPARE, TWO_D_COMPARE_PLAN_LEVEL_ELEVATION_SLIDER);
export const sendTwoDComparePlanLevelElevationInput = () =>
  GA.event(TWO_D_COMPARE, TWO_D_COMPARE_PLAN_LEVEL_ELEVATION_INPUT);
export const sendTwoDCompareEarthLevelOpacitySlider = () =>
  GA.event(TWO_D_COMPARE, TWO_D_COMPARE_EARTH_LEVEL_OPACITY_SLIDER);
export const sendTwoDCompareEarthLevelOpacityInput = () =>
  GA.event(TWO_D_COMPARE, TWO_D_COMPARE_EARTH_LEVEL_OPACITY_INPUT);
export const sendTwoDCompareEarthLevelElevationSlider = () =>
  GA.event(TWO_D_COMPARE, TWO_D_COMPARE_EARTH_LEVEL_ELEVATION_SLIDER);
export const sendTwoDCompareEarthLevelElevationInput = () =>
  GA.event(TWO_D_COMPARE, TWO_D_COMPARE_EARTH_LEVEL_ELEVATION_INPUT);
export const sendTwoDComparePlanLevelApplyDate = () =>
  GA.event(TWO_D_COMPARE, TWO_D_COMPARE_PLAN_LEVEL_APPLY_DATE);
export const sendTwoDComparePlanLevelPanelDelBtn = () =>
  GA.event(TWO_D_COMPARE, TWO_D_COMPARE_PLAN_LEVEL_PANEL_DEL_BTN);
export const sendTwoDComparePlanLevelPanelDownBtn = () =>
  GA.event(TWO_D_COMPARE, TWO_D_COMPARE_PLAN_LEVEL_PANEL_DOWN_BTN);
