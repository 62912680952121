import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import palette from './palette';
import typography from './typography';

const theme = createMuiTheme({
  palette,
  typography,
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    drawer: 1100, // side menu
    appBar: 1200, // header, gnb
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 8,
      },
    },
  },
});

export default theme;
