import { DEFAULT_POLLING_INTERVAL, DEFAULT_QUERY_STALE_TIME } from 'common/policies/request';
import { aerialImageKeys, gcpKeys } from 'query/queryKeys';
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import api from './api';
import { keys } from './queryKeys';
import { GcpRecommendation } from './types';

export function useGcpLocationQuery({
  snapshotId,
  gcpId,
  locationId,
}: Parameters<typeof api.read>[0]) {
  return useQuery(
    keys.read(snapshotId, gcpId, locationId),
    () => api.read({ snapshotId, gcpId, locationId }),
    {
      enabled: Boolean(snapshotId && gcpId && locationId),
      staleTime: DEFAULT_QUERY_STALE_TIME,
      initialData: null,
    },
  );
}

export function useGcpLocationMutation() {
  const queryClient = useQueryClient();

  const invalidateRelatedQueries = (snapshotId: number) => {
    queryClient.invalidateQueries(aerialImageKeys.list(snapshotId));
    queryClient.invalidateQueries(gcpKeys.list(snapshotId));
  };

  const createGcpLocation = useMutation(
    (payload: Parameters<typeof api.create>[0]) => api.create(payload),
    {
      onSuccess: (data, variables) => {
        invalidateRelatedQueries(variables.snapshotId);
      },
    },
  );
  const updateGcpLocation = useMutation(
    (payload: Parameters<typeof api.update>[0]) => api.update(payload),
    {
      onSuccess: (_, variables) => {
        invalidateRelatedQueries(variables.snapshotId);
      },
    },
  );
  const deleteGcpLocation = useMutation(
    (payload: Parameters<typeof api.delete>[0]) => api.delete(payload),
    {
      onSuccess: (_, v) => {
        invalidateRelatedQueries(v?.snapshotId);
      },
    },
  );
  const deleteGcpLocationList = useMutation((payload: any) => api.deleteList(payload), {
    onSuccess: (_, variables) => {
      invalidateRelatedQueries(variables.snapshotId);
    },
  });
  return {
    createGcpLocation,
    updateGcpLocation,
    deleteGcpLocation,
    deleteGcpLocationList,
  };
}

export function useGcpRecommendationMutation() {
  const queryClient = useQueryClient();
  const recommendGcp = useMutation(
    (payload: Parameters<typeof api.createGcpRecommendation>[0]) =>
      api.createGcpRecommendation(payload),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(keys.recommendation(variables.snapshotId));
      },
    },
  );
  const abortGcpRecommendation = useMutation(
    (payload: Parameters<typeof api.abortGcpRecommendation>[0]) =>
      api.abortGcpRecommendation(payload),
  );
  return { recommendGcp, abortGcpRecommendation };
}

export function useGcpRecommendationQuery(
  snapshotId: number,
  options?: UseQueryOptions<GcpRecommendation, Error>,
) {
  const queryClient = useQueryClient();
  const { enabled = true, ...rest } = options || {};
  return useQuery(keys.recommendation(snapshotId), () => api.getGcpRecommendation({ snapshotId }), {
    enabled: Boolean(snapshotId) && enabled,
    staleTime: DEFAULT_QUERY_STALE_TIME,
    refetchInterval: (d) =>
      d?.data?.progressStatus === 'CONVERSION' ? DEFAULT_POLLING_INTERVAL : false,
    onSuccess: (res) => {
      if (res?.data?.progressStatus === 'DONE') {
        queryClient.invalidateQueries(aerialImageKeys.list(snapshotId));
      }
    },
    onError: (error) => {
      console.log(error);
    },
    ...rest,
  });
}
