import { Action } from 'store/types';
import constants from './constants';
import { SnapshotData } from './types';

const actions = {
  setVisibility(type: keyof SnapshotData['visibilities'], id: number | string, visible: boolean) {
    return {
      type: constants.SET_VISIBILITY,
      payload: { type, id, visible },
    };
  },
  setOpacity(type: keyof SnapshotData['opacities'], id: number, opacity: number) {
    return {
      type: constants.SET_OPACITY,
      payload: { type, id, opacity },
    };
  },
  setViewport(payload: Partial<SnapshotData['viewport']>) {
    return {
      type: constants.SET_VIEWPORT,
      payload,
    };
  },
};

// 모든 속성이 Action을 반환하는 함수임을 보장하되,
// export되는 객체에서 타입 추론이 정상적으로 동작할 수 있도록 보장하는 코드
actions as { [key: string]: (...args: any) => Action };

export default actions;

export type ActionType<T extends keyof typeof actions> = ReturnType<typeof actions[T]>;
