import { apiAxios } from 'common/api/common';
import { StaticFileType } from './types';

export default {
  async readFile({ type }: { type: StaticFileType }) {
    const response = await apiAxios.get(`/static/${type}`);
    return response.data;
  },
  async listProj4Definition() {
    const response = await apiAxios.get('/static/coordinates/used');
    return response.data?.results || [];
  },
  async listCoordinates({ search, limit }: { search: string; limit: string }) {
    const response = await apiAxios.get(`/static/coordinates?search=${search}&limit=${limit}`);
    return response.data?.results || [];
  },
};
