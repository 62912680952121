import { getJWTToken } from 'common/utils';
import { Snapshot } from 'store/v2/snapshot/types';
import { defaultClient as client, setAuthHeader } from '../../../common/api/common';

export default {
  create({ zone_id, payload }: { zone_id: number; payload: Snapshot }) {
    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.post(`/v3/zones/${zone_id}/snapshots`, payload);
  },
  read({ zone_id, id, token }: { zone_id: number; id: number; token: string }) {
    setAuthHeader(token, client);
    return client.get(`/v3/zones/${zone_id}/snapshots/${id}`);
  },
  update({ zone_id, id, payload }: { zone_id: number; id: number; payload: Snapshot }) {
    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.patch(`/v3/zones/${zone_id}/snapshots/${id}`, payload);
  },
  delete({ zone_id, id }: { zone_id: number; id: number }) {
    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.delete(`/v3/zones/${zone_id}/snapshots/${id}`);
  },
  list(payload) {
    const { zone_id } = payload;
    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.get(`/v3/zones/${zone_id}/snapshots`);
  },
  process(payload) {
    const { zone_id, snapshot_id } = payload;
    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.patch(`/v3/zones/${zone_id}/snapshots/${snapshot_id}`, {
      processing_status: 'RUNNING_REQUEST',
    });
  },
  pause(payload) {
    const { zone_id, snapshot_id } = payload;
    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.patch(`/v3/zones/${zone_id}/snapshots/${snapshot_id}`, {
      processing_status: 'PAUSE_REQUEST',
    });
  },
};
