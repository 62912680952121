import { ActionType } from './actions';
import constants from './constants';
import { DrawingData } from './types';

export default function reducer(
  state: DrawingData = { vectorLayers: {} },
  action:
    | ActionType<'setDrawings'>
    | ActionType<'setDrawing'>
    | ActionType<'setVectorLayers'>
    | ActionType<'setUploadingDrawings'>
    | ActionType<'setUploadingDrawing'>
    | ActionType<'initializeVectorLayers'>,
) {
  switch (action.type) {
    case constants.SET_DRAWINGS: {
      const { payload } = action as ActionType<'setDrawings'>;
      return { ...state, ...payload };
    }
    case constants.SET_DRAWING: {
      const { payload } = action as ActionType<'setDrawing'>;
      const updated =
        state?.data && state.data.length > 0
          ? state.data.map((x) => (x.id === payload?.item?.id ? payload?.item : x))
          : [payload?.item];
      return { ...state, ...payload, data: updated, item: payload?.item };
    }
    case constants.SET_VECTOR_LAYERS: {
      const { payload } = action as ActionType<'setVectorLayers'>;

      return {
        ...state,
        vectorLayers: {
          ...state.vectorLayers,
          [payload.layerId]: payload.geoJsonList,
        },
      };
    }
    case constants.INITIALIZE_VECTOR_LAYERS: {
      return { ...state, vectorLayers: {} };
    }
    case constants.SET_UPLOADING_DRAWINGS: {
      const { payload } = action as ActionType<'setUploadingDrawings'>;

      return { ...state, uploadingData: payload };
    }
    case constants.SET_UPLOADING_DRAWING: {
      const { payload } = action as ActionType<'setUploadingDrawing'>;

      return {
        ...state,
        uploadingData: state?.uploadingData.map((x) =>
          x?.tempId === payload.tempId ? { ...x, ...payload } : x,
        ),
      };
    }
    default:
      return state;
  }
}
