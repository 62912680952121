import { grey } from './grey';
import { text } from './text';
import { hexToRgba } from './utils';

/** rgba(29, 33, 37, 0.13) */
const DIVIDER = hexToRgba(text.PRIMARY, 0.13);
/** rgba(29, 33, 37, 0.5) */
const BACKDROP = hexToRgba(text.PRIMARY, 0.5);
const SNACKBAR_BACKGROUND = '#1D2125';
const TRANSPARENT = 'transparent';
/** rgba(29, 33, 37, 0.15) */
const BORDER = hexToRgba(text.PRIMARY, 0.15);
const TOOLTIP_BACKGROUND = grey.GREY_900;

export const others = {
  DIVIDER,
  BACKDROP,
  SNACKBAR_BACKGROUND,
  TRANSPARENT,
  BORDER,
  TOOLTIP_BACKGROUND,
};
