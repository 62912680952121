import { Atom, PrimitiveAtom, WritableAtom, atom } from 'jotai';
import { HazardArea } from 'query/hazardArea/types';

type HazardAreaState = {
  id: HazardArea['id'];
  /* visible이 필요한 모든 곳에서 사용 */
  visible?: boolean;
  /* map에서만 보여줘야하는 경우 사용 */
  visibleOnMap?: boolean;
};

const hazardAreaEnabledAtom = atom<boolean>(false);
const hazardAreaAccordionExpandedAtom = atom<boolean>(false);
const hazardAreaStatesAtom = atom<HazardAreaState[]>([]);

export const hazardAreaAtom = {
  enabled: hazardAreaEnabledAtom,
  expanded: hazardAreaAccordionExpandedAtom,
  states: hazardAreaStatesAtom,
};

/** @deprecated */
interface HazardAreaAtom {
  expanded: PrimitiveAtom<boolean>;
  checked: {
    items: PrimitiveAtom<{ [x: number]: boolean }>;
    all: {
      write: WritableAtom<boolean, [update: boolean], void>;
      read: Atom<boolean>;
      indeterminate: Atom<boolean>;
    };
  };
  visible: { map: Atom<boolean>; menu: Atom<boolean> };
}

/** @deprecated */
export const hazardArea: HazardAreaAtom = {
  expanded: atom(false),
  checked: {
    items: atom({} as { [x: number]: boolean }),
    all: {
      write: atom(false, (get, set, update: boolean) => {
        const items = get(hazardArea.checked.items);
        const idList = Object.keys(items);
        if (idList?.length === 0) {
          set(hazardArea.checked.all.write, update);
          return;
        }

        const indeterminate = get(hazardArea.checked.all.indeterminate);
        const newItems = idList.reduce(
          (acc, key) => ({ ...acc, [key]: indeterminate ? false : update }),
          {},
        );
        set(hazardArea.checked.items, newItems);
      }),
      read: atom((get) => {
        const items = get(hazardArea.checked.items);
        const current = get(hazardArea.checked.all.write);
        const values = Object.values(items);
        return values?.length > 0 ? values?.every((v) => v) : current;
      }),
      indeterminate: atom((get) => {
        const items = get(hazardArea.checked.items);
        const values = Object.values(items);
        return values?.length > 0 ? values?.some((v) => v) && values?.some((v) => !v) : false;
      }),
    },
  },
  visible: {
    map: atom((get) => {
      const items = get(hazardArea.checked.items);
      const values = Object.values(items);
      return values?.some((v) => v);
    }),
    menu: atom((get) => {
      const mapVisible = get(hazardArea.visible.map);
      return mapVisible || get(hazardArea.expanded);
    }),
  },
};
