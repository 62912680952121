import { apiAxios } from 'common/api/common';
import { VehicleDrivingRoute, VehicleHistoryResponse } from 'query/vehicle/types';

interface Props {
  projectId: number;
  baseTime: string;
  page?: number;
  search?: string;
  startAt?: string;
  endAt?: string;
  vehicleType?: string;
  historyId?: string;
  date?: string;
}

export default {
  async list({ projectId }: Pick<Props, 'projectId'>) {
    const response = await apiAxios.get(`/api/v2/projects/${projectId}/laborers?type=vehicle`);
    return response?.data;
  },
  async listHistory({ projectId, baseTime }: Pick<Props, 'projectId' | 'baseTime'>) {
    const response = await apiAxios.get(
      `/api/v2/projects/${projectId}/laborers/location?base_time=${encodeURIComponent(
        baseTime,
      )}&type=vehicle`,
    );
    return response?.data;
  },
  async listHistoryV2({
    projectId,
    page,
    search,
    startAt,
    endAt,
    vehicleType,
  }: Omit<Props, 'baseTime'>) {
    const response = await apiAxios.get<VehicleHistoryResponse>(
      `/api/v2/projects/${projectId}/laborers/history?type=vehicle`,
      {
        params: {
          page,
          vehicle_type: vehicleType,
          name: search,
          start_at: startAt,
          end_at: endAt,
        },
      },
    );
    return response?.data;
  },
  async historyExistDate({
    projectId,
    startAt,
    endAt,
  }: Pick<Props, 'projectId' | 'startAt' | 'endAt'>) {
    const response = await apiAxios.get<number[]>(
      `/api/v2/projects/${projectId}/laborers/history/exist-date?type=vehicle`,
      { params: { start_at: startAt, end_at: endAt } },
    );
    return response?.data;
  },
  async locationExistDate({ projectId, date }: Pick<Props, 'projectId' | 'date'>) {
    const response = await apiAxios.get<{ dates: string[] }>(
      `/api/v2/projects/${projectId}/laborers/location/exist-date`,
      { params: { date, type: 'vehicle' } },
    );
    return response?.data;
  },
  async historyDrivingRouteData({ projectId, historyId }: Pick<Props, 'projectId' | 'historyId'>) {
    const response = await apiAxios.get<VehicleDrivingRoute>(
      `api/v2/projects/${projectId}/laborers/history/${historyId}/vehicle/route`,
    );
    return response?.data;
  },
  async historyExcelDownload({
    projectId,
    page,
    search,
    startAt,
    endAt,
    vehicleType,
  }: Omit<Props, 'baseTime'>) {
    return await apiAxios.get(`/api/v2/projects/${projectId}/laborers/history/excel`, {
      params: {
        page,
        vehicle_type: vehicleType,
        name: search,
        start_at: startAt,
        end_at: endAt,
        type: 'vehicle',
      },
      responseType: 'blob',
    });
  },
};
