import { FeatureCollection } from '@turf/helpers';
import { DrawingType } from 'query/resource/types';
import { Action } from 'store/types';
import constants from './constants';
import { DrawingData, DrawingPositioning, UploadingDrawing } from './types';

const actions = {
  listDrawings(snapshotId: number, initial: boolean = false) {
    return {
      type: constants.LIST_DRAWINGS,
      payload: { snapshotId, initial },
    };
  },
  readDrawing(snapshotId: number, drawingId: number) {
    return {
      type: constants.READ_DRAWING,
      payload: { snapshotId, drawingId },
    };
  },
  createDrawings(
    snapshotId: number,
    type: DrawingType,
    drawings: UploadingDrawing[],
    coordinate: string,
  ) {
    return {
      type: constants.CREATE_DRAWINGS,
      payload: { snapshotId, type, drawings, coordinate },
    };
  },
  cancelCreateDrawings() {
    return {
      type: constants.CANCEL_CREATE_DRAWINGS,
      payload: {},
    };
  },
  updatePositioningDrawing(
    drawingId: number,
    dataId: DrawingPositioning['conversionData']['id'],
    payload: { data: Partial<DrawingPositioning['conversionData']['data']> },
  ) {
    return {
      type: constants.UPDATE_POSITIONING_DRAWING,
      payload: { drawingId, dataId, ...payload },
    };
  },
  deleteDrawing(snapshotId: number, drawingId: number) {
    return {
      type: constants.DELETE_DRAWING,
      payload: { snapshotId, drawingId },
    };
  },
  retryDrawing(snapshotId: number, drawingId: number) {
    return {
      type: constants.RETRY_DRAWING,
      payload: { snapshotId, drawingId },
    };
  },
  abortDrawing(snapshotId: number, drawingId: number) {
    return {
      type: constants.ABORT_DRAWING,
      payload: { snapshotId, drawingId },
    };
  },
  cancelListDrawings() {
    return {
      type: constants.CANCEL_LIST_DRAWINGS,
      payload: {},
    };
  },
  setDrawing(drawingData: DrawingData) {
    return {
      type: constants.SET_DRAWING,
      payload: drawingData,
    };
  },
  setDrawings(drawingData: DrawingData) {
    return {
      type: constants.SET_DRAWINGS,
      payload: drawingData,
    };
  },
  setVectorLayers(layerId: number, geoJsonList: FeatureCollection[]) {
    return {
      type: constants.SET_VECTOR_LAYERS,
      payload: { layerId, geoJsonList },
    };
  },
  initializeVectorLayers() {
    return {
      type: constants.INITIALIZE_VECTOR_LAYERS,
      payload: {},
    };
  },
  setUploadingDrawings(drawings: UploadingDrawing[]) {
    return {
      type: constants.SET_UPLOADING_DRAWINGS,
      payload: drawings,
    };
  },
  setUploadingDrawing(drawing: UploadingDrawing) {
    return {
      type: constants.SET_UPLOADING_DRAWING,
      payload: drawing,
    };
  },
};

// 모든 속성이 Action을 반환하는 함수임을 보장하되,
// export되는 객체에서 타입 추론이 정상적으로 동작할 수 있도록 보장하는 코드
actions as { [key: string]: (...args: any) => Action };

export default actions;

export type ActionType<T extends keyof typeof actions> = ReturnType<typeof actions[T]>;
