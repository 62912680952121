import { LatLngBounds, latLngBounds } from 'leaflet';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import v2 from 'store/v2';

interface Props {
  positions: { latitude: number; longitude: number }[];
}
export function useMapCenter({ positions }: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!positions || positions.length === 0) return;

    setMapCenter(getItemBounds(positions));
  }, [positions]);

  function getItemBounds(pos) {
    const firstPosition = { lat: pos[0]?.latitude, lng: pos[0]?.longitude };
    const bounds = latLngBounds(firstPosition, firstPosition);
    pos.forEach((position) => {
      bounds.extend({ lat: position.latitude, lng: position.longitude });
    });
    return bounds;
  }

  function setMapCenter(bounds: LatLngBounds) {
    dispatch(v2.snapshot.actions.setViewport({ center: bounds.getCenter() }));
  }

  function setViewportByPositions(pos: { latitude: number; longitude: number }[]) {
    if (!pos || pos.length === 0) return;

    const bounds = getItemBounds(pos);
    setMapCenter(bounds);
  }

  return { setViewportByPositions };
}
