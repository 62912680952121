import { AxiosError } from 'axios';
import { DEFAULT_POLLING_INTERVAL } from 'common/policies/request';
import { Error } from 'common/types';
import { ImageArchive } from 'query/resource/types';
import { UseQueryOptions, useQuery } from 'react-query';
import api from './api';
import { keys } from './queryKeys';
import { AerialImage } from 'store/v2/aerialImage/types';

interface Props {
  snapshotId: number;
  enabled?: boolean;
}
export function useAerialImageListQuery(
  snapshotId: number,
  options?: UseQueryOptions<AerialImage[]>,
) {
  const { enabled = true, ...restOptions } = options ?? {};
  return useQuery(keys.list(snapshotId), () => api.list(snapshotId), {
    enabled: Boolean(snapshotId),
    ...restOptions,
  });
}

export function useImageArchiveQuery({ snapshotId, enabled }: Props) {
  return useQuery<ImageArchive, AxiosError<Error>, ImageArchive>(
    keys.archive(snapshotId),
    () => api.archive(snapshotId),
    {
      enabled: Boolean(snapshotId && enabled),
      refetchInterval: DEFAULT_POLLING_INTERVAL,
      retry: false,
      onError: (err) => {}, // useApiErrorHandler가 호출되지 않도록 하기 위함
    },
  );
}
