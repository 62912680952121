import GA from 'ga';
import { TWO_D_GREEN, TWO_D_THER, TWO_D_VEGETATION_INDEX } from 'ga/category';
import { getCheckboxLabel } from 'ga/label';

const TWO_D_THER_ELEVATION_SLIDER_INPUT = `${TWO_D_THER}ElevationSliderInput`;
const TWO_D_THER_ELEVATION_SLIDER = `${TWO_D_THER}ElevationSlider`;
const TWO_D_THER_VIEW_CBOX = `${TWO_D_THER}ViewCbox`;
const TWO_D_GREEN_EDIT_SAVE_BTN = `${TWO_D_GREEN}EditSaveBtn`;
const TWO_D_GREEN_EDIT_DEL_BTN = `${TWO_D_GREEN}EditDelBtn`;
const TWO_D_GREEN_EDIT = `${TWO_D_GREEN}Edit`;
const TWO_GREEN_EDIT_BY_DATE_BTN = `${TWO_D_GREEN}EditByDateBtn`;
const TWO_GREEN_COMPARE_BY_DATE_MENU = `${TWO_D_GREEN}CompareByDateMenu`;
const TWO_GREEN_COMPARE_BY_GREEN_MENU = `${TWO_D_GREEN}CompareByGreenMenu`;
const TWO_GREEN_ADD_BTN = `${TWO_D_GREEN}AddBtn`;
const TWO_GREEN_LIST_ITEM = `${TWO_D_GREEN}ListItem`;
const TWO_GREEN_VIEW_CBOX = `${TWO_D_GREEN}ViewCbox`;

const TWO_D_VEGETATION_INDEX_OPACITY_SLIDER = `${TWO_D_VEGETATION_INDEX}OpacitySlider`;
const TWO_D_VEGETATION_INDEX_OPACITY_INPUT = `${TWO_D_VEGETATION_INDEX}OpacityInput`;
const TWO_D_VEGETATION_INDEX_ELEVATION_SLIDER = `${TWO_D_VEGETATION_INDEX}ElevationSlider`;
const TWO_D_VEGETATION_INDEX_ELEVATION_INPUT = `${TWO_D_VEGETATION_INDEX}ElevationInput`;
const TWO_D_VEGETATION_INDEX_FILE_ADD_BTN = `${TWO_D_VEGETATION_INDEX}FileAddBtn`;
const TWO_D_VEGETATION_INDEX_VARI_VIEW_CBOX = `${TWO_D_VEGETATION_INDEX}VariViewCbox`;
const TWO_D_VEGETATION_INDEX_NDVI_VIEW_CBOX = `${TWO_D_VEGETATION_INDEX}NdviViewCbox`;

export const sendTwoDTherElevationSliderInput = () =>
  GA.event(TWO_D_THER, TWO_D_THER_ELEVATION_SLIDER_INPUT);
export const sendTwoDTherElevationSlider = () => GA.event(TWO_D_THER, TWO_D_THER_ELEVATION_SLIDER);
export const sendTwoDTherViewCbox = (checked: boolean) =>
  GA.event(TWO_D_THER, TWO_D_THER_VIEW_CBOX, getCheckboxLabel(checked));
export const sendTwoDGreenEditSaveBtn = () => GA.event(TWO_D_GREEN, TWO_D_GREEN_EDIT_SAVE_BTN);
export const sendTwoDGreenEditDelBtn = () => GA.event(TWO_D_GREEN, TWO_D_GREEN_EDIT_DEL_BTN);
export const sendTwoDGreenEdit = () => GA.event(TWO_D_GREEN, TWO_D_GREEN_EDIT);
export const sendTwoDGreenEditByDateBtn = () => GA.event(TWO_D_GREEN, TWO_GREEN_EDIT_BY_DATE_BTN);
export const sendTwoDGreenCompareByDateMenu = () =>
  GA.event(TWO_D_GREEN, TWO_GREEN_COMPARE_BY_DATE_MENU);
export const sendTwoDGreenCompareByGreenMenu = () =>
  GA.event(TWO_D_GREEN, TWO_GREEN_COMPARE_BY_GREEN_MENU);
export const sendTwoDGreenAddBtn = () => GA.event(TWO_D_GREEN, TWO_GREEN_ADD_BTN);
export const sendTwoDGreenListItem = () => GA.event(TWO_D_GREEN, TWO_GREEN_LIST_ITEM);
export const sendTwoDGreenViewCbox = (checked: boolean) =>
  GA.event(TWO_D_GREEN, TWO_GREEN_VIEW_CBOX, getCheckboxLabel(checked));

export const sendTwoDVegetationIndexOpacitySlider = () =>
  GA.event(TWO_D_VEGETATION_INDEX, TWO_D_VEGETATION_INDEX_OPACITY_SLIDER);
export const sendTwoDVegetationIndexOpacityInput = () =>
  GA.event(TWO_D_VEGETATION_INDEX, TWO_D_VEGETATION_INDEX_OPACITY_INPUT);
export const sendTwoDVegetationIndexElevationSlider = () =>
  GA.event(TWO_D_VEGETATION_INDEX, TWO_D_VEGETATION_INDEX_ELEVATION_SLIDER);
export const sendTwoDVegetationIndexElevationInput = () =>
  GA.event(TWO_D_VEGETATION_INDEX, TWO_D_VEGETATION_INDEX_ELEVATION_INPUT);
export const sendTwoDVegetationIndexFileAddBtn = () =>
  GA.event(TWO_D_VEGETATION_INDEX, TWO_D_VEGETATION_INDEX_FILE_ADD_BTN);
export const sendTwoDVegetationIndexVariViewCbox = (checked: boolean) =>
  GA.event(
    TWO_D_VEGETATION_INDEX,
    TWO_D_VEGETATION_INDEX_VARI_VIEW_CBOX,
    getCheckboxLabel(checked),
  );
export const sendTwoDVegetationIndexNdviViewCbox = (checked: boolean) =>
  GA.event(
    TWO_D_VEGETATION_INDEX,
    TWO_D_VEGETATION_INDEX_NDVI_VIEW_CBOX,
    getCheckboxLabel(checked),
  );
