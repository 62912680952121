import * as Sentry from '@sentry/react';
import { Api } from 'common/api';
import { MAX_IMAGE_UPLOAD_COUNT_AT_ONCE } from 'common/policies/file';
import requestApi from 'common/requestApi';
import { isLastIndexCached } from 'common/utils';
import chunk from 'lodash/chunk';
import { delay } from 'redux-saga';
import { all, call, cancelled, put, race, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { getS3DirectUploadUrl, s3DirectFileUpload } from 'store/data/sagas/fileUploadSaga';
import actions, { ActionType } from './actions';
import constants from './constants';
import { AerialImage } from './types';

function* createAerialImages(api: Api, { payload: data }: ActionType<'createAerialImages'>) {
  const { snapshotId, files } = data;
  const totalCount = files?.length;
  // 중간에 refresh 되는 것을 막고자 처음에 refresh 로직을 추가함.
  // react-query로 바꿀경우 필요 없음.
  yield put(actions.setState({ create: ['PARTIAL', 0, totalCount] }));

  const callArray = createCallArray(api, { files, snapshotId });
  const chunkedCallArray = chunk(
    callArray,
    MAX_IMAGE_UPLOAD_COUNT_AT_ONCE /** 동시 처리 개수 설정 */,
  );

  let index = 0;
  const isLastIndex = isLastIndexCached(chunkedCallArray);
  while (index < chunkedCallArray.length) {
    const { cancellation } = yield race({
      success: all(chunkedCallArray[index]),
      cancellation: take(constants.CANCEL_CREATE_AERIAL_IMAGES),
    });

    if (cancellation) {
      yield put(actions.setState({ create: 'NONE' }));
      return;
    }
    if (isLastIndex(index)) {
      yield call(delay, 500);
      return;
    }
    index += 1;
  }
}

function createCallArray(api: Api, data: { snapshotId; files }) {
  const { snapshotId, files } = data;
  return Array.from(files).map((aerialImage: AerialImage) => {
    return call(createAerialImage, api, { snapshotId, aerialImage });
  });
}

function* createAerialImage(api: Api, { snapshotId, aerialImage }) {
  try {
    yield put(actions.setUploadingAerialImage({ ...aerialImage, status: 'LOADING' }));
    const { file, name, size } = aerialImage as AerialImage;
    const originFileName = name || '';
    const formData = new FormData();
    formData.append('original', file, name);

    // step 1: API 요청으로 S3 업로드 전송용 URL 및 속성값 조회
    const { isFail: failToGetUploadUrl, responseData: resData } = yield getS3DirectUploadUrl(
      api,
      originFileName,
      size,
    );

    if (failToGetUploadUrl || !resData?.url || !resData?.fields) {
      yield put(actions.setUploadingAerialImage({ ...aerialImage, status: 'ERROR' }));
      Sentry.captureException(new Error('AerialImageUploadError: Failed to get S3 upload URL'), {
        extra: { resData },
      });
      return;
    }

    // step 2: 전달 받은 URL, 속성으로 S3 직접 업로드
    const { isFail: s3UploadFail, resFileName: filename } = yield s3DirectFileUpload(
      api,
      resData,
      file,
    );

    if (s3UploadFail || !filename) {
      yield put(actions.setUploadingAerialImage({ ...aerialImage, status: 'ERROR' }));
      Sentry.captureException(new Error('AerialImageUploadError: Failed to upload file to S3'), {
        extra: { resData },
      });
      return;
    }

    // step 3: S3 직접 업로드 완료 후에 업로드 완료를 Server에 알리기
    const payload = { snapshotId, filename, name: originFileName };
    const { isFail, responseData } = yield requestApi(api.aerialImage.create, payload, {
      turnOffCommonErrorHandling: true,
    });

    if (isFail) {
      yield put(actions.setUploadingAerialImage({ ...aerialImage, status: 'ERROR' }));
      Sentry.captureException(new Error('AerialImageUploadError: Failed to create aerial image'), {
        extra: { payload, responseData },
      });
      return;
    }
    yield put(
      actions.setUploadingAerialImage({ ...aerialImage, ...responseData, status: 'SUCCESS' }),
    );
  } finally {
    if (yield cancelled()) {
      yield put(actions.setUploadingAerialImage({ ...aerialImage, status: 'NONE' }));
    }
  }
}
function* deleteAerialImage(api: Api, { payload }: ActionType<'deleteAerialImage'>) {
  const { snapshotId, aerialImage } = payload;
  const { isFail, responseData } = yield requestApi(api.aerialImage.delete, {
    snapshotId,
    aerialImageId: aerialImage?.id,
  });
  if (isFail) {
    return;
  }
  yield put(actions.setDeletedAerialImage(aerialImage));
}
function* listAerialImages(api: Api, { payload }: ActionType<'listAerialImages'>) {
  yield put(actions.setAerialImages({ status: { list: 'LOADING' }, refetch: payload?.refetch }));
  const { snapshotId } = payload;
  const { isFail, responseData } = yield requestApi(api.aerialImage.list, {
    snapshotId,
  });
  if (isFail) {
    yield put(actions.setAerialImages({ status: { list: 'ERROR' }, refetch: false }));
    return;
  }
  yield put(
    actions.setAerialImages({
      status: { list: 'SUCCESS' },
      data: responseData?.results,
      refetch: false,
    }),
  );
}

export default function* aerialImageSaga(api: Api) {
  yield all([takeLatest(constants.CREATE_AERIAL_IMAGES, createAerialImages.bind(undefined, api))]);
  yield all([takeEvery(constants.DELETE_AERIAL_IMAGE, deleteAerialImage.bind(undefined, api))]);
  yield all([takeLatest(constants.LIST_AERIAL_IMAGES, listAerialImages.bind(undefined, api))]);
}
