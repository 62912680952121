import { CancelToken } from 'axios';
import { getJWTToken } from 'common/utils';
import { defaultClient as client, multiClient } from './common';

export default {
  uploadFiles(payload) {
    const { query, filename, size } = payload;
    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
    return client.post(`/upload_files${query}`, { filename, size });
  },
  directUploadToS3(payload: {
    url: string;
    fields: { [key: string]: string };
    file: File;
    cancelToken: CancelToken;
  }) {
    const { url, fields, file, cancelToken } = payload;
    // 응답으로 받은 모든 fields를 formData로 만들어 전송해야함.
    // 순서 유지 필요: fields의 key값이 file 데이터 보다 위에 위치해야 함.
    const formData = new FormData();
    Object.entries(fields).forEach(([k, v]) => formData.append(k, v));
    formData.append('file', file);

    // aws S3로 요청할때, 기존에 설정된 JWT가 전송되지 않도록 임의로 초기화
    multiClient.deleteHeader('Authorization');
    return multiClient.post(url, formData, { cancelToken });
  },
  completeMultipartUpload({ url, data }: { url: string; data: string }) {
    client.deleteHeader('Authorization');
    return client.post(url, data, { headers: { 'Content-Type': 'text/xml' }, timeout: 3_600_000 });
  },
};
