import { apiAxios } from 'common/api/common';
import { ImageArchive } from 'query/resource/types';
import { AerialImage } from 'store/v2/aerialImage/types';

export default {
  // TODO: aerialImage CRUD는 v2에서 옮겨오기
  async list(snapshotId: number) {
    const response = await apiAxios.get<{ results: AerialImage[] }>(
      `/api/v3/snapshots/${snapshotId}/aerialimages`,
    );
    return response?.data?.results;
  },
  async archive(snapshotId: number) {
    const response = await apiAxios.get<ImageArchive>(
      `/api/v3/snapshots/${snapshotId}/aerialimages/archive`,
    );
    return response?.data;
  },
};
